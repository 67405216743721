import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {checkLinkForRoute} from "utils/routingHelpers";


export default function RouteOrLink(props) {
    const [isRoute, setIsRoute] = useState(false);
    const [route, setRoute] = useState(false);

    useEffect(() => {
        const { routeLink, useRoute } = checkLinkForRoute(props.link, props.appRoute);
        setIsRoute(useRoute);
        setRoute(routeLink);
    }, []);

    const handleMouseOver = () => {
        if(props.onMouseOver) {
            props.onMouseOver(props.id);
        }
    }

    const handleClick = (evt) => {
        if(props.onClick) {
            props.onClick(isRoute);
        }
    }

    if(isRoute) {
        return (
            <Link
                to={route}
                className={props.className||""}
                onMouseOver={handleMouseOver}
                onClick={handleClick}
            >
                {props.children}
            </Link>
        );
    }

    return (
        <a
            href={props.link}
            className={props.className||""}
            onMouseOver={handleMouseOver}
            onClick={handleClick}
        >
            {props.children}
        </a>
    );
}

RouteOrLink.propTypes = {
    appRoute: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.number.isRequired,
    link: PropTypes.string.isRequired,
    onMouseOver: PropTypes.func,
    onClick: PropTypes.func,
}

