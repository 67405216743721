
import {combineReducers} from "redux";
import * as ActionTypes from "constants/ActionTypes";
import keyBy from "lodash/keyBy";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";

function promotionalPage(state = {}, action) {
    switch (action.type) {
        case ActionTypes.PROMO_PAGE_DETAILS_LOADED:
            return action.promo;
        default:
            return state;
    }
}

function promoPageDownload(state = null, action) {
    switch (action.type) {
        case ActionTypes.PROMO_DOWNLOAD_SUCCESS:
            return {
                ...action.download,
            }
        default:
            return state;
    }
}

function newsletterSignup(state=false, action) {
    switch (action.type) {
        case ActionTypes.NEWSLETTER_SIGNUP_SUCCESS:
            return true;
        case ActionTypes.NEWSLETTER_SIGNUP_ERROR:
            return false;
        default:
            return state;
    }
}

function menuLinks(state = null, action) {
    switch (action.type) {
        case ActionTypes.MENU_ITEMS_LOADED:
            const groupedItems = groupBy(action.menuLinks, "showInMenuBar");
            const menuBar = orderBy(groupedItems.true, ["order"], ["asc"]);
            const menuItems = keyBy(groupedItems.false, "id");
            return {
                menuBar,
                menuItems,
                defaultMenuItem: groupedItems.false[0],
            }
        default:
            return state;

    }
}

function staff(state= null, action) {
    switch (action.type) {
        case ActionTypes.STAFF_LOADED:
            return keyBy(action.bios, "id");
        case ActionTypes.STAFF_DETAILS_LOADED:
            return {
                ...state,
                [action.bio.id]: action.bio,
            }
        default:
            return state;
    }
}

function events(state={}, action) {
    switch (action.type) {
        case ActionTypes.PUBLIC_EVENTS_LOADED:
        case ActionTypes.PUBLIC_EVENT_MENU_ITEMS_LOADED:
            return {
                ...state,
                ...keyBy(action.events, "uniqueId"),
            }
        case ActionTypes.PUBLIC_EVENT_DETAILS_LOADED:
            return {
                ...state,
                [action.event.uniqueId]: action.event,
            }
        default:
            return state;
    }
}

function eventTypes(state = null, action) {
    switch (action.type) {
        case ActionTypes.EVENT_TYPES_LOADED:
            return {
                ...state,
                ...keyBy(action.eventTypes, "eventType.slug"),
            }
        case ActionTypes.EVENT_TYPE_DETAILS_LOADED:
            return {
                ...state,
                [action.slug]: action.eventType,
            }
        default:
            return state;
    }
}

export default combineReducers({
    staff,
    menuLinks,
    events,
    eventTypes,
    newsletterSignup,
    promoPageDownload,
    promotionalPage,
});
