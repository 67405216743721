import React from "react";
import classnames from "classnames";
import styles from "./SubsectionBlock.scss";
import getStaticFile from "utils/getStaticFile";

export default function SubsectionBlock(props) {
    const backgroundImage = getStaticFile(`img/${props.header}_sub_bg.jpg`);
    return (
        <div
            className={classnames(styles.root, {
                [styles.middle]: props.position === "middle",
                [styles.left]: props.position === "left",
                [styles.right]: props.position === "right",
            })}
            style={{ background: `url(${backgroundImage}) no-repeat scroll top center`, backgroundSize: `cover`}}
        >
            <div className={styles.overlay} style={{ backgroundColor: props.tint }}/>
            <h3 className={styles.header}>
                {props.header}.
            </h3>
        </div>
    );
}
