import React from "react";
import styles from "./Footer.scss";

import getStaticFile from "utils/getStaticFile";
const logo = getStaticFile("img/logo_white.png");
const logo_edu = getStaticFile("img/logo_white_edu.png");

export default function Footer(props) {
    const logoImg = props.edu ? logo_edu : logo;
    const year = new Date().getFullYear();
    return (
        <div className={styles.footer}>
            <a href="https://jazzwire.net" title="JazzWire Homepage" ><img src={logoImg} alt="JazzWire logo"/></a>
            <div className={styles.footerLinks}>
                <ul>
                    <li className="header">About JazzWire</li>
                    <li><a href="http://jazzwire.net/" target="_blank">JazzWire.net</a></li>
                    <li><a href="http://jazzwire.net/terms-and-conditions/" target="_blank">Terms and Conditions</a>
                    </li>
                    <li><a href="http://jazzwire.net/privacy-policy/" target="_blank">Privacy Policy</a></li>
                </ul>
                <ul>
                    <li className={styles.footerHeader}>Account</li>
                    <li />
                    <li />
                    <li><a href="/sign-in" className="ng-star-inserted">Sign In</a></li>
                    <li><a href="/sign-up" className="ng-star-inserted">Sign Up</a></li>
                </ul>
                <ul>
                    <li className="header">Help</li>
                    <li><a href="mailto:support@jazzwire.net">Contact Us: support@jazzwire.net</a></li>
                    <li><a href="https://www.youtube.com/watch/EFBmo1_aRQs" target="_blank">Show Tutorial</a></li>
                </ul>
            </div>
            <div className={styles.version}>Version 2.4.3 &bull; Copyright &copy; {year} JazzWire - All Rights Reserved. - <a href="https://www.swordsweeper.com" style={{color: "rgba(255, 255, 255,0.5)"}}>Development by Swordsweeper</a></div>
        </div>
    );
}
