import React from "react";
import PropTypes from "prop-types";
import styles from "./HeaderBlock.scss";
import classnames from "classnames";
import SiteNav from "components/shared/widgets/SiteNav";

export default function HeaderBlock(props) {
    return (
        <header className={classnames(styles.root,{
            [styles.shorter]: props.shorter,
            [styles.longText]: props.longText,
        })} style={{ backgroundImage: `url(${props.backgroundImage})`, backgroundSize: `cover`}}>
            <h1>JazzWire</h1>
            <div className="container">
                <div className={styles.headerLinks}>
                    <div className={styles.logo}>
                        <a href="/" title="JazzWire.net homepage">
                            <img src={props.logo} />
                        </a>
                    </div>
                    {props.children}
                </div>
                <div className={styles.mainText}>
                    <p className={styles.tagLine}>
                        {props.tagLine}
                    </p>
                    <p className={styles.subText}>
                        {props.subText}
                    </p>
                </div>
            </div>
        </header>
    );
}

HeaderBlock.propTypes = {
    backgroundImage: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    tagLine: PropTypes.any.isRequired,
    subText: PropTypes.string,
    longText: PropTypes.bool,
    shorter: PropTypes.bool,
};
