import React, {useEffect} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default function Button(props) {
    const isIconButton = !!props.buttonIcon && !props.buttonText;
    return (
        <button 
            className={classnames(props.className, "btn", {
                "buttonNoBg" : props.noBackground,
                "buttonNoBorder" : props.noBorder,
                "btn-full": !props.noBackground && !isIconButton,
                "fullWidth": props.fullWidth,
                "disabled": props.disabled,
                "btn-white": props.buttonColor === "white",
                "btn-light": props.buttonColor === "light",
                "btn-light-blue": props.buttonColor === "blue",
                "icon-only": props.iconOnly,
                "icon": !!props.buttonIcon,
                "icon-btn": isIconButton,
                "flex-none": props.noFlex,
                "pulsate": props.pulsate,
                "small": props.small,
            })}
            onClick={props.onClick}
            disabled={props.disabled}
            data-testid={`button_${props.buttonText}`}
        >
            {props.buttonIcon && <i className={props.buttonIcon} />}{props.buttonText}
        </button>
    );
}

Button.propTypes = {
    buttonText: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonIcon: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    noBackground: PropTypes.bool,
    noBorder: PropTypes.bool,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    iconOnly: PropTypes.bool,
    noFlex: PropTypes.bool,
    pulsate: PropTypes.bool,
    className: PropTypes.any,
    largeIcon: PropTypes.bool,
    small: PropTypes.bool,
};
