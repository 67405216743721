import map from "lodash/map";

export const bioData = (bio) => {
    const { image, user: { avatar }} = bio;
    const profileImage = image ? image.file : avatar;

    return {
        ...bio,
        profileImage,
    }
}

export const bioList = (bios) => {
    return map(bios, bio => bioData(bio));
}