
import React, {useState, useEffect} from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import styles from "./MainMenu.scss";
import classnames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import HamburgerMenu from "components/homepage/elements/HamburgerMenu";
import RouteOrLink from "components/shared/elements/RouteOrLink";
import getStaticFile from "utils/getStaticFile";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import {checkLinkForRoute} from "utils/routingHelpers";

const loginMenuBGImage = getStaticFile("img/login_menu_bg.jpg");
const defaultMenuBGImage = getStaticFile("img/default_menu_bg.jpg");

const DEFAULT_SUBMENU = {
    image: {
        file: defaultMenuBGImage,
    }
};

const LOGIN_SUBMENU = {
    image: {
        file: loginMenuBGImage,
    }
};

const el = document.createElement("div");

export default function MainMenu(props) {
    const [menuContent, setMenuContent] = useState(DEFAULT_SUBMENU);
    const [eventItems, setEventItems] = useState(null);

    useEffect(() => {
        document.getElementById("modal-root").appendChild(el);

        return () => {
            document.getElementById("modal-root").removeChild(el)
        };
    }, []);

    useEffect(() => {
        if(props.events) {
            const eventList = map(props.events, eventData => ({
                id: eventData.uniqueId,
                label: eventData.title,
                link: `/events/details/${eventData.uniqueId}/`,
                subLabel: getEventSubLabel(eventData),
            }));

            setEventItems(eventList);
        }
    }, [props.events]);

    const getEventSubLabel = (eventData) => {
        const date = moment(eventData.eventDate).format("MM/DD/YYYY");
        const location  = eventData.location ? `@ ${eventData.location.name}` : '';
        return `${date} ${location}`
    }

    const handleUpdateActiveItem = (itemId) => {
        let activeMenu = DEFAULT_SUBMENU;

        if(itemId === "login") {
            activeMenu = LOGIN_SUBMENU;
        } else if(!!itemId) {
            activeMenu = props.menuItems?.[`${itemId}`];
            if(activeMenu.link === "/events/") {
                activeMenu = {
                    ...activeMenu,
                    submenuLinks: eventItems,
                };
            }
        }

        setMenuContent(activeMenu);
    }

    const handleLinkClicked = (isRoute) => {
        if(isRoute) {
            props.onClose();
        }
    }

    const renderActiveContent = () => {
        return (
            <div
                className={styles.activeMenu}
            >
                {!!menuContent.submenuLinks?.length && (
                    <div className={styles.submenuItems}>
                        {map(orderBy(menuContent.submenuLinks, ["order"], ["asc"]).slice(0,5), item => (
                            <RouteOrLink
                                appRoute={props.currentApp}
                                className={styles.menuLink}
                                id={item.id}
                                label={item.label}
                                link={item.link}
                                key={`sublink_${item.id}`}
                                onClick={handleLinkClicked}
                            >
                                <span>{item.label}</span>
                                {!!item.subLabel && <span className={styles.subLabel}>{item.subLabel}</span>}
                            </RouteOrLink>
                        ))}
                    </div>
                )}
                {!!menuContent.messageWindow && (
                    <div className={styles.messageWindow}>
                        <div className={styles.message}>
                            {menuContent.messageWindow}
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const renderMenuItems = () => {
        return map(orderBy(props.menuItems, ["order"], ["asc"]), item => (
            <RouteOrLink
                appRoute={props.currentApp}
                className={classnames(styles.menuLink, {
                    [styles.active]: menuContent?.id === item.id,
                })}
                id={item.id}
                label={item.label}
                link={item.link}
                key={`link_${item.id}`}
                onMouseOver={handleUpdateActiveItem}
                onClick={handleLinkClicked}
            >
                <span>{item.label}</span>
            </RouteOrLink>
        ));
    };

    const renderComponent = () => {
        if (!props.show) {
            return null;
        }

        return (
            <div
                className={styles.root}
            >
                <div
                    className={styles.modalBackdrop}
                />
                <div className={styles.modalContainer}>
                    <div className={classnames(styles.modalBodyContainer)}>
                        <div className={styles.backgrounds}>
                            <div className={styles.linksBackground} />
                            <div className={styles.imageBackground} style={{backgroundImage: `url(${menuContent.image?.file})`}} />
                        </div>
                        <div className="container">
                            <div className={styles.headerLinks}>
                                <div className={styles.logo}>
                                    <a href="/" title="JazzWire.net homepage">
                                        <img src={props.logo} />
                                    </a>
                                </div>
                                <span className={styles.closeBtn}>
                                    <HamburgerMenu bars isOpen onClick={props.onClose} />
                                </span>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.contentInner}>
                                <div className={styles.menuLinks}>
                                    {renderMenuItems()}
                                    <a
                                        href="/app"
                                        className={classnames(styles.menuLink, styles.login)}
                                        onMouseOver={() => handleUpdateActiveItem("login")}
                                    >
                                        LOGIN
                                    </a>
                                </div>
                                <div className={styles.subMenu}>
                                    {renderActiveContent()}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }

    return ReactDOM.createPortal(
        renderComponent(),
        el,
    );
}

MainMenu.propTypes = {
    logo: PropTypes.string.isRequired,
    clickOutsideToClose: PropTypes.bool,
    onClose: PropTypes.func,
    show: PropTypes.bool,
    useRoutes: PropTypes.bool,
    menuItems: PropTypes.object,
    events: PropTypes.object,
};
