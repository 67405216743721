
import {combineReducers} from "redux";
import * as ActionTypes from "constants/ActionTypes";
import produce from "immer";
import findIndex from "lodash/findIndex";
import flatMap from "lodash/flatMap";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import omit from "lodash/omit";
import uniqBy from "lodash/uniqBy";
import without from "lodash/without";
import { act } from "react-dom/test-utils";

function events(state = {}, action) {
    switch (action.type) {
        case ActionTypes.EVENTS_LOADED:
            return {
                ...state,
                ...keyBy(action.eventsResponse.results, "uniqueId"),
            }
        case ActionTypes.EVENT_CREATED:
        case ActionTypes.EVENT_UPDATED:
        case ActionTypes.EVENT_DETAILS_LOADED:
            return {
                ...state,
                [action.event.uniqueId]: action.event,
            };
        case ActionTypes.EVENT_DELETED:
            return omit(state, action.event);
        case ActionTypes.EVENT_FOLLOWED:
            return produce(state, (draftState) => {
                const event = draftState[action.eventId];
                if (event.followers.includes(action.userId)) {
                    event.followers = without(post.followers, action.userId);
                } else {
                    event.followers.push(action.userId);
                }
                draftState[action.eventId] = event;
                return draftState;
            });
        default:
            return state;
    }
}

function eventGroups(state = {}, action) {
    switch (action.type) {
        case ActionTypes.EVENT_GROUPS_LOADED:
            return {
                ...state,
                ...keyBy(action.eventGroups, "id"),
            }
        default:
            return state;
    }
}

function posts(state={}, action) {
    switch (action.type) {
        case ActionTypes.CLEAR_POST_FEED:
            return {};
        case ActionTypes.POST_FEED_LOADED:
            return {
                ...state,
                ...keyBy(action.postResponse.results, "uniqueId"),
            };
        case ActionTypes.POST_CREATED:
        case ActionTypes.POST_UPDATED:
        case ActionTypes.POST_DETAILS_LOADED:
            return {
                ...state,
                [action.post.uniqueId]: action.post,
            };
        case ActionTypes.POST_DELETED:
            return omit(state, action.post);
        case ActionTypes.POST_FOLLOWED:
            return produce(state, (draftState) => {
                const post = draftState[action.postId];
                if (post.followers.includes(action.userId)) {
                    post.followers = without(post.followers, action.userId);
                } else {
                    post.followers.push(action.userId);
                }
                draftState[action.postId] = post;
                return draftState;
            });
        case ActionTypes.VIDEO_POSTER_UPDATED:
            const videoPosts = keyBy(map(action.video.posts, vidP => {
                return produce(state[vidP.uniqueId], (draftPost) => {
                    draftPost.video = action.video;
                    return draftPost;
                });
            }), "uniqueId");

            return {
                ...state,
                ...videoPosts,
            };
        default:
            return state;
    }
}

function recentPosts(state={}, action) {
    switch (action.type) {
        case ActionTypes.RECENT_POSTS_LOADED:
            return {
                ...state,
                ...keyBy(action.recentPosts, "uniqueId"),
            };
        case ActionTypes.POST_UPDATED:
        case ActionTypes.POST_CREATED:
            return {
                ...state,
                [action.post.uniqueId]: action.post,
            };
        case ActionTypes.POST_DELETED:
            return omit(state, action.post);
        default:
            return state;
    }
}

function upcomingEvents(state={}, action) {
    switch (action.type) {
        case ActionTypes.UPCOMING_EVENTS_LOADED:
            return {
                ...state,
                ...keyBy(action.upcomingEvents, "uniqueId"),
            };
        case ActionTypes.EVENT_UPDATED:
        case ActionTypes.EVENT_CREATED:
            return {
                ...state,
                [action.event.uniqueId]: action.event,
            };
        case ActionTypes.EVENT_DELETED:
            return omit(state, action.event);
        default:
            return state;
    }
}


function featuredPosts(state= null, action) {
    switch (action.type) {
        case ActionTypes.CLEAR_FEATURED_POSTS:
            return null;
        case ActionTypes.FEATURED_POSTS_LOADED:
            return action.featuredPosts.results;
        default:
            return state;
    }
}

function newMembers(state={}, action) {
    switch (action.type) {
        case ActionTypes.NEW_MEMBERS_LOADED:
            return action.newMembers;
        default:
            return state;
    }
}

function videoPosts(state=[], action) {
    switch (action.type) {
        case ActionTypes.VIDEO_ARCHIVE_LOADED:
            return uniqBy([
                ...state,
                ...action.videoArchive.results,
            ], "uniqueId");
        case ActionTypes.CLEAR_VIDEO_ARCHIVE_RESULTS:
            return [];
        default:
            return state;
    }
}

function scheduledPosts(state={}, action) {
    switch(action.type) {
        case ActionTypes.SCHEDULED_POSTS_LOADED:
            return {
                ...state,
                ...keyBy(action.postResponse, "uniqueId"),
            };
        case ActionTypes.SCHEDULED_POST_CREATED:
            return {
                ...state,
                [action.post.uniqueId]: action.post,
            };
        case ActionTypes.POST_DELETED:
            return omit(state, action.post);
        default:
            return state;
    }
}

function commentHierarchyComments(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENT_AND_REPLIES_LOADED:
            return {
                ...state,
                [action.comment.id]: {...action.comment},
            }
        case ActionTypes.COMMENT_UPDATED:
            return {
                ...state,
                [action.comment.id]: action.comment,
            };
        case ActionTypes.COMMENT_DELETED:
            return omit(state, action.comment.id);
        case ActionTypes.COMMENT_AND_REPLIES_ERROR:
            return {
                ...state,
                [action.commentId]: {
                    missing: true,
                },
            }
        default:
            return state;
    }
}


function commentHierarchyReplies(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENT_AND_REPLIES_LOADED:
            return {
                ...state,
                ...keyBy(action.replies, "id"),
            }
        case ActionTypes.REPLY_DELETED:
            return produce(state, draftState => {
                delete draftState[action.comment.id];
                return draftState;
            });
        case ActionTypes.REPLY_UPDATED:
        case ActionTypes.REPLY_POSTED:
            return {
                ...state,
                [action.comment.id]: action.comment,
            };
        case ActionTypes.REPLIES_RELOADED:
            return {
                ...state,
                ...keyBy(action.replyResult.results, "id"),
            };
        case ActionTypes.COMMENT_AND_REPLIES_ERROR:
            return {
                ...state,
                [action.commentId]: {
                    missing: true,
                },
            }
        default:
            return state;
    }

}

function totalComments(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENTS_LOADED:
            return {
                ...state,
                [action.postId]: action.commentResponse.total,
            };
        case ActionTypes.COMMENTS_RELOADED:
            return {
                ...state,
                [action.postId]: state[action.postId] + action.commentResponse.total,
            };
        case ActionTypes.COMMENT_POSTED:
            return {
                ...state,
                [action.comment.post]: state[action.comment.post] + 1
            };
        case ActionTypes.COMMENT_DELETED:
            return {
                ...state,
                [action.comment.post]: state[action.comment.post] - 1
            };
        default:
            return state;
    }
}

function comments(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENTS_LOADED:
            return {
                ...state,
                ...keyBy(action.commentResponse.results, "id"),

                // TODO: move these to a different reducer so this is single use.
                total: action.commentResponse.total,
            };
        case ActionTypes.COMMENTS_RELOADED:
            return {
                ...state,
                ...keyBy(action.commentResponse.results, "id"),
                total: state.total + action.commentResponse.results.length,
            }
        case ActionTypes.COMMENT_UPDATED:
        case ActionTypes.COMMENT_POSTED:
            return {
                ...state,
                [action.comment.id]: action.comment,
            };
        case ActionTypes.COMMENT_DELETED:
            return omit(state, action.comment.id);
        default:
            return state;
    }
}

function replies(state={}, action) {
    switch (action.type) {
        case ActionTypes.REPLY_DELETED:
            return produce(state, draftState => {
                delete draftState[action.comment.id];
                return draftState;
            });
        case ActionTypes.REPLY_UPDATED:
        case ActionTypes.REPLY_POSTED:
            return {
                ...state,
                [action.comment.id]: action.comment,
            };
        case ActionTypes.COMMENTS_LOADED:
            const replies = keyBy(flatMap(action.commentResponse.results, (com) => {
                return com.replies.results;
            }), "id");
            return {
                ...state,
                ...replies,
            };
        case ActionTypes.REPLIES_LOADED:
        case ActionTypes.REPLIES_RELOADED:
            return {
                ...state,
                ...keyBy(action.replyResult.results, "id"),
            };
        default:
            return state;
    }
}

// function users(state={}, action) {
//     switch (action.type) {
//         case ActionTypes.USER_LOADED:
//             return {
//                 ...state,
//                 [action.user.id]: action.user,
//             };
//         default:
//             return state;
//     }
// }

function communities(state={}, action) {
    switch (action.type) {
        case ActionTypes.CURRENT_STUDIO_LOADED:
            return {
                ...state,
                ...keyBy(action.studio.communities, "id"),
            };
        default:
            return state;
    }
}

function privateGroups(state={}, action) {
    switch (action.type) {
        case ActionTypes.CURRENT_STUDIO_LOADED:
            return {
                ...state,
                ...keyBy(action.studio.privateGroups, "id"),
            };
        case ActionTypes.PRIVATE_GROUP_LOADED:
            return {
                ...state,
                [action.privateGroup.id]: action.privateGroup,
            }
        default:
            return state;
    }
}

function parentGroups(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CURRENT_STUDIO_LOADED:
            return {
                ...state,
                ...keyBy(action.studio.parentGroups, "id"),
            };
        default:
            return state;
    }
}

function publicGroups(state={}, action) {
    switch (action.type) {
        case ActionTypes.CURRENT_STUDIO_LOADED:
            return {
                ...state,
                ...keyBy(action.studio.groups, "id"),
            };
        case ActionTypes.PUBLIC_GROUP_CREATED:
            return {
                ...state,
                [action.publicGroup.id]: action.publicGroup
            }
        default:
            return state;
    }
}

function userMap(state=[], action) {
    switch (action.type) {
        case ActionTypes.MAP_LOCATIONS_LOADED:
            return action.locations;
        default:
            return state;
    }
}

function locations(state={}, action) {
    switch (action.type) {
        case ActionTypes.LOCATIONS_LOADED:
        case ActionTypes.LOCATION_UPDATED:
            return action.locations;
        default:
            return state;
    }
}

function userEvaluations(state={}, action) {
    switch (action.type) {
        case ActionTypes.APPLICATION_QUEUE_LOADED:
            return {
                ...state,
                ...keyBy(action.userEvaluations, "id"),
            };
        case ActionTypes.USER_EVALUATION_DELETED:
            return omit(state, action.evaluation);
        case ActionTypes.USER_EVALUATION_FINALIZED:
            return omit(state, action.evaluationId);
        default:
            return state;
    }
}

function userEvaluation(state={}, action) {
    switch (action.type) {
        case ActionTypes.USER_EVALUATION_CREATED:
        case ActionTypes.USER_EVALUATION_LOADED:
        case ActionTypes.USER_EVALUATION_UPDATED:
            return {
                ...action.userEvaluation,
                fields: keyBy(action.userEvaluation.fields, "id")
            };
        case ActionTypes.USER_EVALUATION_FIELD_CREATED:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.evaluationFields.id]: action.evaluationFields,
                },
            };
        default:
            return state;
    }
}

function evaluationResponseTemplates(state={}, action) {
    switch (action.type) {
        case ActionTypes.EVALUATION_RESPONSE_TEMPLATES_LOADED:
            return {
                ...state,
                ...keyBy(action.evaluationResponseTemplates, "id"),
            };
        default:
            return state;
    }
}

function instrumentCategories(state={}, action) {
    switch (action.type) {
        case ActionTypes.INSTRUMENT_CATEGORIES_LOADED:
            return {
                ...state,
                ...keyBy(action.instrumentCategories, "id"),
            };
        default:
            return state;
    }
}

function instruments(state={}, action) {
    switch (action.type) {
        case ActionTypes.INSTRUMENTS_LOADED:
            return {
                ...state,
                ...keyBy(action.instruments, "id"),
            };
        case ActionTypes.INSTRUMENT_CREATED:
            return {
                ...state,
                [action.instrument.id]: action.instrument,
            };
        default:
            return state;
    }
}

function influencers(state={}, action) {
    switch (action.type) {
        case ActionTypes.INFLUENCERS_LOADED:
            return {
                ...state,
                ...keyBy(action.influencers, "id"),
            };
        case ActionTypes.INFLUENCER_CREATED:
            return {
                ...state,
                [action.influencer.id]: action.influencer,
            };
        default:
            return state;
    }
}

function stripeTargetAccounts(state={}, action) {
    switch (action.type) {
        case ActionTypes.STRIPE_TARGET_ACCOUNTS_LOADED:
            return {
                ...state,
                ...keyBy(action.stripeTargetAccounts, "id"),
            };
        case ActionTypes.STRIPE_TARGET_ACCOUNT_UPDATED:
        case ActionTypes.STRIPE_TARGET_ACCOUNT_CREATED:
            return {
                ...state,
                [action.stripeTargetAccount.id]: action.stripeTargetAccount,
            };
        case ActionTypes.STRIPE_TARGET_ACCOUNT_DELETED:
            return omit(state, action.stripeTargetAccountId);
        default:
            return state;
    }
}

function userDetails(state={}, action) {
    switch (action.type) {
        case ActionTypes.USER_DETAILS_LOADED:
            return {
                ...state,
                [action.userDetails.id] : action.userDetails,
            };
        default:
            return state;
    }
}

function studioMembers(state={}, action) {
    switch (action.type) {
        case ActionTypes.STUDIO_MEMBERS_LOADED:
            return action.studioMembers;
        default:
            return state;
    }
}

function communityMembers(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMUNITY_MEMBERS_LOADED:
            return action.studioMembers;
        default:
            return state;
    }
}

function latestQuote(state={}, action) {
    switch (action.type) {
        case ActionTypes.MOST_RECENT_QUOTE_LOADED:
            return action.quote;
        default:
            return state;
    }
}

function quotes(state={}, action) {
    switch (action.type) {
        case ActionTypes.QUOTES_LOADED:
            return {
                ...state,
                ...keyBy(action.quotes, "id"),
            }
        case ActionTypes.QUOTE_LOADED:
        case ActionTypes.QUOTE_CREATED:
        case ActionTypes.QUOTE_UPDATED:
            return {
                ...state,
                [action.quote.id]: action.quote
            }
        case ActionTypes.QUOTE_DELETED:
        default:
            return state;
    }
}

function registrationForm(state={}, action) {
    switch (action.type) {
        case ActionTypes.REGISTRATION_FORM_LOADED:
            return {
                ...state,
                [action.form.uniqueId]: action.form,
            }
        default:
            return state;
    }
}

const updateLikes = (currentState, id, userProfile) => {
    return produce(currentState, (draftState) => {
        let likedBy = map(draftState[id], user => user);
        const likedUserIndex = findIndex(likedBy, like => like.user.id === userProfile.id);
        if (likedUserIndex > -1) {
            likedBy = [
                ...likedBy.slice(0, likedUserIndex),
                ...likedBy.slice(likedUserIndex+1),
            ];
        } else {
            likedBy.push({
                user: userProfile,
                id: userProfile.id,
            });
        }
        draftState[id] = likedBy;
        return draftState;
    });
}

function postLikes(state={}, action) {
    switch (action.type) {
        case ActionTypes.POST_LIKES_LOADED:
            return {
                ...state,
                [action.postId]: action.likedBy,
            }
        case ActionTypes.POST_LIKED:
            return updateLikes(state, action.postId, action.userProfile);
        default:
            return state;
    }
}

function eventLikes(state={}, action) {
    switch (action.type) {
        case ActionTypes.EVENT_LIKES_LOADED:
            return {
                ...state,
                [action.eventId]: action.likedBy,
            }
        case ActionTypes.EVENT_LIKED:
            return updateLikes(state, action.eventId, action.userProfile);
        default:
            return state;
    }
}

function commentLikes(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENT_LIKES_LOADED:
        case ActionTypes.REPLY_LIKES_LOADED:
            return {
                ...state,
                [action.commentId]: action.likedBy,
            }
        case ActionTypes.COMMENT_LIKED:
        case ActionTypes.REPLY_LIKED:
            return updateLikes(state, action.commentId, action.userProfile);
        default:
            return state;
    }
}

function audioFiles(state ={}, action) {
    switch (action.type) {
        case ActionTypes.AUDIO_DETAILS_LOADED:
            return {
                ...state,
                [action.audio.id]: action.audio,
            }
        default:
            return state;
    }
}

function promoPages(state={}, action) {
    switch (action.type) {
        case ActionTypes.PROMO_PAGES_LOADED:
            return keyBy(action.promos, "id");
        case ActionTypes.PROMO_PAGE_UPDATED:
        case ActionTypes.PROMO_PAGE_CREATED:
        case ActionTypes.PROMO_PAGE_LOADED:
            return {
                ...state,
                [action.promo.id]: action.promo,
            }
        default:
            return state;
    }
}

function blogPages(state={}, action) {
    switch (action.type) {
        case ActionTypes.BLOG_PAGES_LOADED:
            return keyBy(action.blogs, "id");
        case ActionTypes.BLOG_PAGE_UPDATED:
        case ActionTypes.BLOG_PAGE_CREATED:
        case ActionTypes.BLOG_PAGE_LOADED:
            return {
                ...state,
                [action.blog.id]: action.blog,
            }
        default:
            return state;
    }
}

function contactLists(state={}, action) {
    switch (action.type) {
        case ActionTypes.CONTACT_LISTS_LOADED:
            return keyBy(action.lists, "id");
        default:
            return state;
    }
}

function siteNotifications(state={}, action) {
    switch (action.type) {
        case ActionTypes.SITE_NOTIFICATIONS_LOADED:
            return keyBy(action.siteNotifications, "id");
        case ActionTypes.SITE_NOTIFICATION_UPDATED:
        case ActionTypes.SITE_NOTIFICATION_CREATED:
        case ActionTypes.SITE_NOTIFICATION_DETAILS_LOADED:
            return {
                ...state,
                [action.siteNotification.id]: action.siteNotification,
            }
        default:
            return state;
    }
}


export default combineReducers({
    audioFiles,
    comments,
    commentHierarchyComments,
    commentHierarchyReplies,
    commentLikes,
    communities,
    contactLists,
    evaluationResponseTemplates,
    eventGroups,
    events,
    eventLikes,
    featuredPosts,
    influencers,
    instrumentCategories,
    instruments,
    latestQuote,
    locations,
    newMembers,
    posts,
    postLikes,
    parentGroups,
    privateGroups,
    publicGroups,
    quotes,
    recentPosts,
    registrationForm,
    replies,
    scheduledPosts,
    stripeTargetAccounts,
    studioMembers,
    totalComments,
    // users,
    userDetails,
    userEvaluations,
    userEvaluation,
    userMap,
    videoPosts,
    upcomingEvents,
    promoPages,
    blogPages,
    communityMembers,
    siteNotifications,
});
