import * as PromoApi from "api/PromoApi";
import * as SiteNotificationApi from "api/SiteNotificationApi"
import * as SiteApi from "api/SiteApi";
import * as ActionTypes from "constants/ActionTypes";
import {toaster} from "utils/toaster";
import {requestError} from "utils/request";
import * as ReformatData from "utils/reformatData";
import {loadPublicEventsMenu} from "api/SiteApi";
import {EventSessions} from "constants/EventSessions";

export function loadPromoDetails(promoId) {
    return (dispatch) => {
        PromoApi.loadPromoDetails(promoId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PROMO_PAGE_DETAILS_LOADED,
                    promo: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error loading promotional page details");
            });
    };
}

export function getPromoDownload(promoId, formData) {
    return (dispatch) => {
        PromoApi.getPromoDownload(promoId, formData)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PROMO_DOWNLOAD_SUCCESS,
                    download: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error loading promotional page details");
            });
    };
}

export function loadPromoPages() {
    return (dispatch) => {
        PromoApi.loadPromoPages()
            .then((res) => {
                dispatch({
                    type: ActionTypes.PROMO_PAGES_LOADED,
                    promos: res.body,
                })
            }).catch((err) => {
                requestError(err, "Error loading promo pages");
            });
    }
}

export function loadPromoPage(promoId) {
    return (dispatch) => {
        PromoApi.loadPromoPage(promoId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PROMO_PAGE_LOADED,
                    promo: res.body,
                })
            }).catch((err) => {
                requestError(err, "Error loading promo pages");
            });
    }
}

export function updatePromoPage(data, posterId) {
    return (dispatch) => {
        PromoApi.updatePromoPage(data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PROMO_PAGE_UPDATED,
                    promo: res.body,
                    posterId,
                });
                toaster.success("Promotional Page Updated!");
            }).catch((err) => {
                requestError(err, "Error updating promo page");
            });
    }
}

export function createPromoPage(promoData, posterId) {
    return (dispatch) => {
        PromoApi.createPromoPage(promoData)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PROMO_PAGE_CREATED,
                    promo: res.body,
                    posterId,
                });
                toaster.success("Promotional Page Created!");
            }).catch((err) => {
                requestError(err, "Error creating promo page");
            });
    }
}

export function clonePromoPage(promoId) {
    return (dispatch) => {
        PromoApi.clonePromoPage(promoId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PROMO_PAGE_CREATED,
                    promo: res.body,
                })
                toaster.success("Promotional Page Duplicated!");
            }).catch((err) => {
                requestError(err, "Error creating promo page");
            });
    }
}

export function clearPosterSuccess(posterId) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CLEAR_SUCCESS,
            posterId,
        });
    }
}

export function loadContactLists() {
    return (dispatch) => {
        SiteApi.loadContactLists()
            .then((res) => {
                dispatch({
                    type: ActionTypes.CONTACT_LISTS_LOADED,
                    lists: res.body,
                })
            }).catch((err) => {
                requestError(err, "Error creating promo page");
            });
    }
}

export function loadMenuLinks() {
    return (dispatch) => {
        SiteApi.loadMenuLinks()
            .then((res) => {
                dispatch({
                    type: ActionTypes.MENU_ITEMS_LOADED,
                    menuLinks: res.body,
                })
            }).catch((err) => {
                requestError(err, "Error creating promo page");
            });
    }
}

export function loadStaff() {
    return (dispatch) => {
        SiteApi.loadStaff()
            .then((res) => {
                dispatch({
                    type: ActionTypes.STAFF_LOADED,
                    bios: ReformatData.bioList(res.body),
                })
            }).catch((err) => {
                requestError(err, "Error loading instructors");
            });
    }
}

export function loadBios() {
    return (dispatch) => {
        SiteApi.loadBios()
            .then((res) => {
                dispatch({
                    type: ActionTypes.BIOS_LOADED,
                    bios: ReformatData.bioList(res.body),
                })
            }).catch((err) => {
                requestError(err, "Error loading bios");
            });
    }
}

export function loadBioDetails(bioId, instructor = false) {
    return (dispatch) => {
        const type = instructor ? ActionTypes.STAFF_DETAILS_LOADED : ActionTypes.BIO_DETAILS_LOADED;
        SiteApi.loadBioDetails(bioId)
            .then((res) => {
                dispatch({
                    type,
                    bio: ReformatData.bioData(res.body),
                })
            }).catch((err) => {
                requestError(err, "Error loading bio");
            });
    }
}

export function loadBioBySlug(slug, instructor = false) {
    return (dispatch) => {
        const type = instructor ? ActionTypes.STAFF_DETAILS_LOADED : ActionTypes.BIO_DETAILS_LOADED;
        SiteApi.loadBioBySlug(slug)
            .then((res) => {
                dispatch({
                    type,
                    bio: ReformatData.bioData(res.body),
                })
            }).catch((err) => {
                requestError(err, "Error loading bio");
            });
    }
}

export function loadPublicEvents(slug='') {
    return (dispatch) => {
        SiteApi.loadPublicEvents(slug)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PUBLIC_EVENTS_LOADED,
                    slug,
                    events: res.body.results,
                })
            }).catch((err) => {
                requestError(err, "Error loading public events");
            });
    }
}

export function loadPublicEventDetails(eventId) {
    return (dispatch) => {
        SiteApi.loadPublicEventDetails(eventId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PUBLIC_EVENT_DETAILS_LOADED,
                    event: res.body,
                })
            }).catch((err) => {
                requestError(err, "Error loading public events");
            });
    }
}

export function loadPublicEventMenuLinks() {
    return (dispatch) => {
        SiteApi.loadPublicEventsMenu()
            .then((res) => {
                dispatch({
                    type: ActionTypes.PUBLIC_EVENT_MENU_ITEMS_LOADED,
                    events: res.body,
                })
            }).catch((err) => {
                requestError(err, "Error loading event menu links");
            });
    }
}

export function loadEventTypes() {
    return (dispatch) => {
        // SiteApi.loadEventTypes()
        //     .then((res) => {
        //         dispatch({
        //             type: ActionTypes.EVENT_TYPE_DETAILS_LOADED,
        //             eventTypes: res.body,
        //         })
        //     }).catch((err) => {
        //         requestError(err, "Error loading event types");
        //     });
        dispatch({
            type: ActionTypes.EVENT_TYPES_LOADED,
            eventTypes: EventSessions,
        })
    }
}

