import React, {useEffect, useState} from "react";
import styles from "./Homepage.scss";
import classnames from "classnames";
import queryString from "query-string";
import SiteContainer from "components/homepage/SiteContainer";
import TextBlock from "components/homepage/widgets/TextBlock";
import SubsectionBlock from "components/homepage/widgets/SubsectionBlock";
import ImageBlock from "components/homepage/widgets/ImageBlock";
import MeetJeff from "components/homepage/widgets/MeetJeff";
import StartTodayBlock from "components/homepage/widgets/StartTodayBlock";
import SignUpBlock from "components/homepage/widgets/SignUpBlock";
import QuoteBlock from "components/homepage/widgets/QuoteBlock";
import VideoBlock from "components/homepage/widgets/VideoBlock";
import getStaticFile from "utils/getStaticFile";
import JoinNewsletterModal from "../../site/widgets/JoinNewsletterModal";

const headerBackgroundImage = getStaticFile("img/header_bg.jpg");
const isLoggedIn = window.ENV.LOGGED_IN;

export default function HomepageContent(props) {

    const [showNewsletterSignup, setShowNewsletterSignup] = useState(false);

    useEffect(() => {
        const searchQuery = window.location?.search ? queryString.parse(window.location.search) : {};
        if (searchQuery.newsletter) {
            setShowNewsletterSignup(true);
        }
    }, [window.location]);

    return (
        <SiteContainer
            headerImage={headerBackgroundImage}
            tagLine={<span>Get back to one of your first <span>loves</span>.</span>}
            subText="Learn, play, and share the Jazz Music you love."
        >
            <TextBlock
                content={<span><b>JazzWire is built for adult students</b>, so you'll get an honest assessment of where you are from someone who has worked with thousands of adult jazz students over several decades. Imagine having a renowned jazz musician tell you where you are in your playing today. What are your strengths, what areas could improve, and how to make it all happen? The personal approach is where we begin!</span>}
                color="rgb(219, 182, 2)"
                lineHeight={"1.75em"}
                leftAlign
            />
            <VideoBlock
                source="homepage_loop_2022.mp4"
                poster="practice_bg.jpg"
                muxPlaybackId="01xIP00LIUZMsUPH3Vv6vp33CMvOq00NyGi"
            />
            <div className={styles.row}>
                <SubsectionBlock
                    id={1}
                    header="learn"
                    tint="rgba(3, 252, 240, 0.8)"
                    position="left"
                />
                <SubsectionBlock
                    id={2}
                    header="play"
                    tint="rgba(141, 146, 170, 0.8)"
                    position="middle"
                />
                <SubsectionBlock
                    id={3}
                    header="share"
                    tint="rgba(214, 182, 61, 0.8)"
                    position="right"
                />
            </div>
            <ImageBlock
                header="Plans, Classes & Live Events"
                imageFile={"practice"}
                gradient
                description="A variety of online and offline options are available to help you learn, play, and share jazz music"
            />
            <TextBlock
                leftAlign
                content={<span><b>JazzWire</b> offers a series of online & offline services that will help you learn, play and share jazz music. Each session, whether online or in-person, is led by our talented and inspiring jazz coach/performers and features exclusive audio and video content, individual coach-to-player direct feedback, pdf lesson packs, workshop recordings and much more. Plus, you'll be part of a highly engaged community of adult learners like yourself who share a passion for jazz improvisation.</span>}
                color="rgb(35, 40, 50)"
            />

            <ImageBlock
                header={"Evaluation & Practice Plan"}
                imageFile={"learn"}
                gradient
                description="Get a written road map made personally for you, based on your playing evaluation."
                decoration={{ type: "waves_horizontal", top: "15%", left: "-0.5%", maxWidth: "30%" }}
            />
            <TextBlock
                leftAlign
                content={<span><b>Once we evaluate your playing</b> (via video or audio), you'll receive a Practice Plan, a written road map made personally for you, and new video lessons every week for your skill level. Song study, theory, comping & rhythm, soloing, rhythm techniques, and styles are all just the beginning. You'll find specific assignments and stepwise, attainable short-term goals and over 500 archived videos, all searchable by content, style, song, topic, and other videos, all searchable by content, style, song, topic, and other keywords. Evaluations are done twice a year so we can update your practice plan.</span>}
                color="rgb(29, 197, 192)"
            />
            <ImageBlock
                header="Community"
                imageFile="community"
                gradient
                description="Imagine having an online group at similar skill levels to share, practice together, and collaborate."
                decoration={{ type: "dots", top: "50%", right: "5%", transform: "translateY(-50%)", maxWidth: "20%" }}
            />
            <TextBlock
                leftAlign
                content={<span><b>Like the great music schools</b> and universities of the world, the daily support and inspiration from your JazzWire Community provide unlimited inspiration to take your playing to the next level. Plus, with our expert video recording and online sharing tips, you'll find it easy to share your music and collaborate with other musicians. JazzWire communities are not a wide-open, come-one, come-all experience. There are powerful, curated communities of engaged and driven musicians. This is where the fun really happens.</span>}
                color="rgb(141, 146, 170)"
            />

            <ImageBlock
                header="Great Resources"
                imageFile="share"
                gradient
                description="More input on your playing and progress than you've likely ever experienced before."
                decoration={{ type: "waves_vertical", top: "-0.5%", right: "25%", maxWidth: "12%" }}
            />
            <TextBlock
                leftAlign
                content={<span><b>JazzWire's combination</b> of evaluations, lessons, and online community are great motivators to play more and practice more efficiently. Besides your practice plan, weekly videos, and jazz community, over 500 archived videos are searchable by content, style, song, topic, and other keywords.</span>}
                color="rgb(219, 182, 2)"
            />

            <MeetJeff
                missionStatement="On a mission to change the lives of adult amateurs and semi-pros!"
                bio="Jeff Antoniuk shares his 20+ years of real world experience teaching adult amateurs and semi-pros, and elegantly transposes it to the virtual world of online connection."
            />
            <StartTodayBlock />
            {/*<SignUpBlock />*/}
            <div className={classnames(styles.row, styles.extraMargin)}>
                <QuoteBlock
                    id={1}
                    quoteBold="Jeff's educational offerings are fantastic."
                    quoteRemainder=" I learn something new every time I watch a video. The JazzWire process is innovative and a real breakthrough for adult jazz education."
                    author="Jeff Y."
                    backgroundColor="rgb(219, 182, 2)"
                    secondaryColor="rgb(201, 67, 177)"
                    primaryColor="yellow"
                    fontColor="#000"
                />
                <QuoteBlock
                    id={2}
                    quoteBold="My practicing is much more focused and organized since I joined JazzWire in 2019."
                    quoteRemainder=" I am not only a better improviser, but a much better musician. I can't recommend JazzWire highly enough."
                    author="Emily T."
                    backgroundColor="rgb(201, 67, 177)"
                    secondaryColor="rgb(219, 182, 2)"
                    primaryColor="pink"
                    fontColor="#fff"
                />
                <QuoteBlock
                    id={3}
                    quoteBold="JazzWire has been a godsend for me."
                    quoteRemainder=" My evaluation was spot on, and everything on the site is stellar - Jeff's lessons, his daily comments, and the conversations in the communities. I am really improving my playing, and having a lot of fun doing it."
                    author="Reggie W."
                    backgroundColor="rgb(219, 182, 2)"
                    secondaryColor="rgb(201, 67, 177)"
                    primaryColor="yellow"
                    fontColor="#000"
                />
            </div>
            {showNewsletterSignup && <JoinNewsletterModal onClose={() => setShowNewsletterSignup(false)} contactList={4} />}
            {!isLoggedIn && (
                <a className={classnames(styles.floatingButton)} href="/sign-up">Join Now</a>
            )}
        </SiteContainer>
    );
}
