import React from "react";
import PropTypes from "prop-types";
import styles from "./Loader.scss";
import classnames from "classnames";
import ProgressBar from "components/shared/elements/ProgressBar";
import { PROGRESS_COLORS }  from "constants/Colors";

export default function Loader(props) {
    return (
        <div className={classnames(styles.root, {
            [styles.dark]: props.dark,
            [styles.hasText]: props.loaderText,
        })}>
            <div className={styles.loaderText}>
                {props.loaderText}
                {props.loaderSubText && (
                    <span className={styles.subText}><br/>{props.loaderSubText}</span>
                )}
            </div>
            {props.progressBar && !!props.progressPercent ? (
                <div className={styles.progressBarWrapper}>
                    <ProgressBar
                        colors={PROGRESS_COLORS()}
                        thresholds={[0,75,95]}
                        percent={props.progressPercent}
                        border
                    />
                    <span className={styles.progressText}>{`${Math.round(props.progressPercent)}%`}</span>
                </div>
            ) : (
                <div className={styles.loader} />
            )}
        </div>
    );
}

Loader.propTypes = {
    loaderText: PropTypes.string,
    loaderSubText: PropTypes.string,
    progressBar: PropTypes.bool,
    progressPercent: PropTypes.number,
    dark: PropTypes.bool,
};
