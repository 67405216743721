import React from "react";
import styles from "./StartTodayBlock.scss";
import getStaticFile from "utils/getStaticFile";
const backgroundImage = getStaticFile("img/start_bg.jpg");

export default function StartTodayBlock(props) {
    return (
        <div className={styles.root} style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: `cover`}}>
            <div className={styles.overlay}/>
            <div className="container">
                <div className={styles.mainText}>
                    Explore JazzWire today for FREE!
                </div>
                <div className={styles.subText}>
                    There's no credit card required and you'll get instant access to the JazzWire Platform.
                </div>
                <div className={styles.signUpButton}>
                    <a
                        className="btn btn-yellow"
                        href="/free-trial"
                        title="Sign Up for JazzWire"
                    >Sign Up</a>
                </div>
            </div>
        </div>
    );
}
