
import request from "utils/request";

export function loadPromoDetails(promoId) {
    return request()
        .get(`/api/v1/promo-details/${promoId}/`)
        .send();
}

export function getPromoDownload(promoId, formData) {
    return request()
        .post(`/api/v1/promo-details/${promoId}/`)
        .send(formData);
}

export function loadPromoPages() {
    return request()
        .get("/api/v1/promos")
        .send();
}

export function loadPromoPage(promoId) {
    return request()
        .get(`/api/v1/promos/${promoId}`)
        .send();
}

export function clonePromoPage(promoId) {
    return request()
        .get(`/api/v1/clone-promo/${promoId}/`)
        .send();
}

export function updatePromoPage(promoData) {
    const { id } = promoData;
    return request()
        .patch(`/api/v1/promos/${id}/`)
        .send(promoData);
}

export function createPromoPage(promoData) {
    return request()
        .post(`/api/v1/promos/`)
        .send(promoData);
}
