import React from "react";
import PropTypes from "prop-types";
import styles from "./HamburgerMenu.scss";
import classnames from "classnames";
import getStaticFile from "utils/getStaticFile";
const  menuBars = getStaticFile("img/menu-bars.png");

export default function HamburgerMenu(props) {
    const handleMenuClick = () => {
        props.onClick();

    }
    const iconClass = "far fa-ellipsis-v";
    const openIconClass = "fal fa-times";
    const barsIcon = props.isOpen ? <i className={openIconClass} /> : <img src={menuBars} />;

    return (

        <div className={classnames(styles.root, {
            [styles.stacked]: props.bars,
        })} onClick={handleMenuClick}>
            <span className={styles.icon}>
                {props.bars ? (
                    barsIcon
                ): (
                    <i className={iconClass} />
                )}
            </span>
            <span className={styles.label}>{props.isOpen ? "Close" : "Menu" }</span>
        </div>
    );
}

HamburgerMenu.propTypes = {
    bars: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
};
