import React from "react";
import styles from "./QuoteBlock.scss";
import getStaticFile from "utils/getStaticFile";
const yellowQuotes = getStaticFile("img/yellow_quotes.png");
const pinkQuotes = getStaticFile("img/pink_quotes.png");
export default function QuoteBlock(props) {

    const backgroundImage = getStaticFile(`img/quote_${props.id}_bg.jpg`);
    const imageOffsets = [-23.5, -23.5, -15];

    return (
        <div className={styles.root}>
            <div className={styles.quote} style={{ backgroundColor: props.backgroundColor, color: props.fontColor }}>
                <div className={styles.icon}>
                    <img src={props.primaryColor === "yellow" ? pinkQuotes : yellowQuotes} alt="Quotation Marks" />
                </div>
                <div>
                    <span><span className={styles.boldText}>{props.quoteBold}</span>{props.quoteRemainder}</span>
                </div>
                <div className={styles.author}>
                    - {props.author}
                </div>
            </div>
            <div className={styles.image}>
                <div className={styles.imageInner}>
                    <img src={backgroundImage} alt={props.author} />
                </div>
            </div>
        </div>
    );
}
