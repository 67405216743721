
const gtag = window.gtag ? window.gtag : () => { console.warn("NO GTAG AVAILABLE")};
const ROOT_DOMAIN = window.location.domain;

const getPageLocation = (location) => {
    const pathname = location?.pathname;
    const search = location?.search;
    if(pathname && search) {
        return `${ROOT_DOMAIN}${pathname}${search}`;
    }

    return window.location;
}

export const sendPageView = (pageName, location={}) => {
    // TODO: GA4 Property > Enhanced Measurement > (Gear) > Page View > Show Advanced Settings
    //  Uncheck "Page changes based on browser history events" to prevent double counts
    const pathname = location.pathname || window.location.path;
    const pageLocation = getPageLocation(location);
    gtag('event', 'page_view', {
      page_path: pathname,
      page_location: pageLocation,
      page_title: pageName || document.title,
    });
}

export const sendEvent = (eventType, data={}) => {
    gtag("event", eventType, data);
}

export const sendVideoEvent = (action, data) => {
    //ACTIONS: "video_start", "video_progress", "video_complete"
    //DATA: { video_current_time, video_duration, video_percent, video_provider, video_title, video_url, visible (boolean) }
    gtag("event", action, data);
}

export const sendSiteNotification = (siteNotificationType, data={}) => {
    gtag("site notification", siteNotificationType, data);
}