import React from "react";
import styles from "./ImageBlock.scss";
import getStaticFile from "utils/getStaticFile";
import classnames from "classnames";
import PropTypes from "prop-types";

export default function ImageBlock(props) {
    const fileName = props.imageFile || props.header;
    const backgroundImage = getStaticFile(`img/header_images/${fileName}_bg.jpg`);
    const { type: decorationType, ...decorationStyles } = props?.decoration || {};
    return (
        <section
            className={classnames(styles.root,{
                [styles.allowFlex]: props.allowFlex,
            })}
            style={{ background: `url(${backgroundImage}) no-repeat scroll top ${props.leftAlign ? "left" : "center"}`, backgroundSize: `cover`}}
        >
            {props.gradient && <div className={classnames(styles.gradient, { [styles.gradientReverse]: props.gradientReverse})}/>}
            {props.description &&
                <div className="container">
                    <div className={styles.textBlock}>
                        {props.showHeader &&
                            <h3 className={styles.header}>
                                {props.header}
                            </h3>
                        }
                        {props.description &&
                            <p className={styles.description}>
                                {props.description}
                            </p>
                        }
                    </div>
                </div>
            }
            {props.decoration &&
                <div className={styles.decoration} style={{...decorationStyles}}>
                    <img src={getStaticFile(`img/decorations/decoration_${decorationType}.png`)} />
                </div>
            }
        </section>
    );
}

ImageBlock.propTypes = {
    header: PropTypes.string.isRequired,
    imageFile: PropTypes.string,
    showHeader: PropTypes.bool,
    gradient: PropTypes.bool,
    gradientReverse: PropTypes.bool,
    description: PropTypes.string,
    decoration: PropTypes.shape({
        top: PropTypes.string,
        bottom: PropTypes.string,
        left: PropTypes.string,
        right: PropTypes.string,
        transform: PropTypes.string,
        type: PropTypes.string,
    }),
    leftAlign: PropTypes.bool,
    allowFlex: PropTypes.bool,
}

ImageBlock.defaultProps = {
    showHeader: true,
};
