import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import styles from "./JoinNewsletterModal.scss";
import { loadContactLists } from "actions/SiteActions";
import Modal from "components/shared/elements/Modal";
import Loader from "components/shared/elements/Loader";
import {joinNewsletter} from "api/SiteApi";
import Input from "components/shared/elements/Input";
import Button from "../../shared/elements/Button";

export default function JoinNewsletterModal(props) {

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const submit = () => {
        setLoading(true);
        joinNewsletter({
            contactListId: props.contactList,
            email,
        }).then((res) => {
            setLoading(false);
            setSuccess(true);
        }).catch((err) => {
            setLoading(false);
            setError(err.message);
        });
    }

    const handleEmailChange = ({ name, value }) => {
        setEmail(value);
    }

    if (loading) {
        return (
            <div className={styles.loaderScreen}>
                <Loader loaderText={"Submitting"} dark />
            </div>
        )
    }

    return (
        <Modal
            show={true}
            onClose={props.onClose}
            clickOutsideToClose
            small
            centered
        >
            <div className={styles.root}>
                {success ? (
                    <React.Fragment>
                        <h2>Thanks for joining our Newsletter!</h2>
                        <p>You'll see our next edition soon.</p>
                        <div className={styles.buttonFooter}>
                            <Button onClick={props.onClose} buttonText={"Close"} buttonColor={"light"} />
                        </div>
                    </React.Fragment>
                ):(
                    <React.Fragment>
                        <h2>Join our Newsletter</h2>
                        <div className={styles.form}>
                            <Input onChange={handleEmailChange} name={"email"} type={"email"} placeholder={"Enter your email address"} />
                            {error && <div className={styles.error}>{error}</div>}
                        </div>
                        <div className={styles.buttonFooter}>
                            <Button onClick={submit} buttonText={"Join"} disabled={!email} />
                        </div>
                    </React.Fragment>
                )}
            </div>
        </Modal>
    );
}

JoinNewsletterModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    contactList: PropTypes.number.isRequired,
};