import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./VideoBlock.scss";
import getStaticFile from "utils/getStaticFile";
import Video from "components/shared/elements/Video";

export default function VideoBlock(props) {
    const videoFile = getStaticFile(`video/${props.source}`);
    const posterImage = getStaticFile(`img/${props.poster}`);
    const playButton = getStaticFile(`img/play_btn.png`);
    const hasText = !!props.header || !!props.description;

    const [showVideo, setShowVideo] = useState(false);
    const hasPlayableVideo = !!props.muxPlaybackId || !!props.youTubeEmbed;
    const muxSrc = `https://stream.mux.com/${props.muxPlaybackId}.m3u8`;

    return (
        <section className={styles.root}>
            <div dangerouslySetInnerHTML={{
                __html:
                `<video
                    width="100%"
                    autoPlay
                    muted
                    loop
                    playsInline
                    poster=` + posterImage + `
                >
                    <source src=` + videoFile + ` type="video/mp4" />
                </video>`
            }}/>
            {props.gradient && <div className={classnames(styles.gradient, {[styles.gradientReverse]: props.gradientReverse })}/>}
            {hasText &&
                <div className={styles.textBlock}>
                    {props.header && (<div className={styles.header}>
                        {props.header}.
                    </div>)}
                    {props.description && (<div className={styles.description}>
                        {props.description}
                    </div>)}
                </div>
            }
            {props.decoration &&
                <div className={styles.decoration} style={{ top: `${props.decoration.top}%`, left: `${props.decoration.left}%`}}>
                    <img src={getStaticFile(`img/decorations/decoration_${props.decoration.type}.png`)} alt="Decoration"/>
                </div>
            }

            {props.link &&
                <div className={styles.linkIcon}>
                    <a href={props.link} target="_blank" title="Play full video">
                        <img src={playButton} alt="Play button" />
                    </a>
                </div>
            }

            {hasPlayableVideo && (
                <React.Fragment>
                    <div className={styles.linkIcon}>
                        <button className="btn noBackground video-play" title="Play full video" onClick={() => setShowVideo(true)}>
                            <img src={playButton} alt="Play button" />
                        </button>
                    </div>
                    {showVideo && (
                        <div className={styles.youTubeVideo}>
                            {props.youTubeEmbed && (
                                <iframe width="100%" height="100%" src={`${props.youTubeEmbed}?modestbranding=1&autoplay=1&iv_load_policy=3`} frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen />
                            )}
                            {props.muxPlaybackId && (
                                <Video muxPlaybackId={props.muxPlaybackId} autoPlay />
                            )}
                            <div className={styles.closeBtn}>
                                <button className="btn icon" onClick={() => setShowVideo(false)}><i className="fas fa-times" />Close</button>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}


        </section>
    );
}

VideoBlock.propTypes = {
    source: PropTypes.string.isRequired,
    poster: PropTypes.string,
    gradient: PropTypes.bool,
    gradientReverse: PropTypes.bool,
    description: PropTypes.string,
    header: PropTypes.string,
    link: PropTypes.string,
    youTubeEmbed: PropTypes.string,
    muxPlaybackId: PropTypes.string,
}
