import React from "react";
import PropTypes from "prop-types";
import styles from "./MinimalHeader.scss";

export default function MinimalHeader(props) {
    return (
        <header className={styles.root}>
            <h1>JazzWire</h1>
            <div className="container">
                <div className={styles.headerLinks}>
                    <div className={styles.logo}>
                        <a href="/" title="JazzWire.net homepage">
                            <img src={props.logo} />
                        </a>
                    </div>
                    {props.children}
                </div>
            </div>
        </header>
    );
}

MinimalHeader.propTypes = {
    logo: PropTypes.string.isRequired,
};
