
export const WINDOW_SIZE_SET = "WINDOW_SIZE_SET";
export const CURRENT_USER_LOADED = "CURRENT_USER_LOADED";
export const CURRENT_USER_UPDATED = "CURRENT_USER_UPDATED";
export const EVALUATION_REMINDER_DISMISSED = "EVALUATION_REMINDER_DISMISSED";
export const CLEAR_EVALUATION_REMINDER = "CLEAR_EVALUATION_REMINDER";
export const USER_TRIAL_CONVERTED = "USER_TRIAL_CONVERTED";
export const CLEAR_USER_TRIAL_CONVERTED = "CLEAR_USER_TRIAL_CONVERTED";
export const USER_REJOIN_STUDIO = "USER_REJOIN_STUDIO";
export const USER_INSTRUMENT_UPDATED = "USER_INSTRUMENT_UPDATED";
export const USER_INSTRUMENT_ADDED = "USER_INSTRUMENT_ADDED";
export const USER_INSTRUMENT_DELETED = "USER_INSTRUMENT_DELETED";
export const USER_DETAILS_LOADED = "USER_DETAILS_LOADED";
export const USER_STUDIO_UPDATED = "USER_STUDIO_UPDATED";
export const CURRENT_STUDIO_LOADED = "CURRENT_STUDIO_LOADED";
export const CURRENT_STUDIO_CHANGED = "CURRENT_STUDIO_CHANGED";
export const STUDIO_MEMBERS_LOADED = "STUDIO_MEMBERS_LOADED";
export const AVAILABLE_COMMUNITIES_LOADED = "AVAILABLE_COMMUNITIES_LOADED";
export const STUDIO_OPTIONS_LOADED = "STUDIO_OPTIONS_LOADED";
export const USER_FOLLOWED = "USER_FOLLOWED";


// POSTS
export const POST_DETAILS_LOADED = "POST_DETAILS_LOADED";
export const POST_LIKES_LOADED = "POST_LIKES_LOADED";
export const POST_DETAILS_LOAD_ERROR = "POST_DETAILS_LOAD_ERROR";
export const CLEAR_POST_FEED = "CLEAR_POST_FEED";
export const POST_FEED_LOADED = "POST_FEED_LOADED";
export const START_POST_FEED_LOAD = "START_POST_FEED_LOAD";
export const POST_FEED_LOAD_ERROR = "POST_FEED_LOAD_ERROR";
export const NEXT_POSTS_LOADED = "NEXT_POSTS_LOADED";

// Featured Posts
export const CLEAR_FEATURED_POSTS = "CLEAR_FEATURED_POSTS";
export const FEATURED_POSTS_LOADED = "FEATURED_POSTS_LOADED";
export const FEATURED_POSTS_LOAD_ERROR = "FEATURED_POSTS_LOAD_ERROR";

export const COMMENTS_LOADED = "COMMENTS_LOADED";
export const COMMENTS_RELOADED = "COMMENTS_RELOADED";
export const START_COMMENTS_LOAD = "START_COMMENTS_LOAD";
export const COMMENTS_LOAD_ERROR = "COMMENTS_LOAD_ERROR";
export const NEXT_COMMENTS_LOADED = "NEXT_COMMENTS_LOADED";

export const SCHEDULED_POSTS_LOADED = "SCHEDULED_POSTS_LOADED";
export const SCHEDULED_POSTS_LOAD_ERROR = "SCHEDULED_POSTS_LOAD_ERROR";
export const SCHEDULED_POST_CREATED = "SCHEDULED_POST_CREATED";
export const SCHEDULED_POST_CREATED_ERROR = "SCHEDULED_POST_CREATED_ERROR";
export const POST_CREATED = "POST_CREATED";
export const POST_CREATED_ERROR = "POST_CREATED_ERROR";
export const POST_UPDATED = "POST_UPDATED";
export const POST_DELETED = "POST_DELETED";
export const POST_LIKED = "POST_LIKED";
export const POST_FOLLOWED = "POST_FOLLOWED";
export const POST_UNLIKED = "POST_UNLIKED";

// EVENTS
export const START_EVENTS_LOAD = "START_EVENTS_LOAD";
export const EVENTS_LOAD_ERROR = "EVENTS_LOAD_ERROR";
export const EVENTS_LOADED = "EVENTS_LOADED";
export const EVENT_DETAILS_LOADED = "EVENT_DETAILS_LOADED";
export const EVENT_FOLLOWED = "EVENT_FOLLOWED";
export const EVENT_CREATED = "EVENT_CREATED";
export const EVENT_CREATED_ERROR = "EVENT_CREATED_ERROR";
export const EVENT_UPDATED = "EVENT_UPDATED";
export const EVENT_DELETED = "EVENT_DELETED";
export const EVENT_LIKED = "EVENT_LIKED";
export const EVENT_LIKES_LOADED = "EVENT_LIKES_LOADED";
export const EVENT_GROUPS_LOADED = "EVENT_GROUPS_LOADED";
export const EVENT_TYPES_LOADED = "EVENT_TYPES_LOADED";
export const EVENT_TYPE_DETAILS_LOADED = "EVENT_TYPE_DETAILS_LOADED";

// COMMENTS
export const COMMENT_POSTED = "COMMENT_POSTED";
export const COMMENT_POSTED_ERROR = "COMMENT_POSTED_ERROR";
export const COMMENT_UPDATED = "COMMENT_UPDATED";
export const COMMENT_DELETED = "COMMENT_DELETED";
export const COMMENT_LIKES_LOADED = "COMMENT_LIKES_LOADED";
export const COMMENT_LIKED = "COMMENT_LIKED";
export const COMMENT_UNLIKED = "COMMENT_UNLIKED";

export const COMMENT_AND_REPLIES_LOADED = "COMMENT_AND_REPLIES_LOADED";
export const COMMENT_AND_REPLIES_ERROR = "COMMENT_AND_REPLIES_ERROR";

export const REPLY_POSTED = "REPLY_POSTED";
export const REPLY_POSTED_ERROR = "REPLY_POSTED_ERROR";
export const REPLY_UPDATED = "REPLY_UPDATED";
export const REPLY_DELETED = "REPLY_DELETED";
export const REPLIES_LOADED = "REPLIES_LOADED";
export const REPLIES_RELOADED = "REPLIES_RELOADED";
export const REPLY_LIKED = "REPLY_LIKED";
export const REPLY_LIKES_LOADED = "REPLY_LIKES_LOADED";

export const NEW_MEMBERS_LOADED = "NEW_MEMBERS_LOADED";
export const NEW_MEMBERS_LOAD_ERROR = "NEW_MEMBERS_LOAD_ERROR";

export const RECENT_POSTS_LOAD_ERROR = "RECENT_POSTS_LOAD_ERROR";
export const RECENT_POSTS_LOADED = "RECENT_POSTS_LOADED";
export const UPCOMING_EVENTS_LOADED = "UPCOMING_EVENTS_LOADED";
export const UPCOMING_EVENTS_LOAD_ERROR = "UPCOMING_EVENTS_LOAD_ERROR";

export const VIDEO_ARCHIVE_LOAD_ERROR = "VIDEO_ARCHIVE_LOAD_ERROR";
export const VIDEO_ARCHIVE_LOADED = "VIDEO_ARCHIVE_LOADED";
export const CLEAR_VIDEO_ARCHIVE_RESULTS = "CLEAR_VIDEO_ARCHIVE_RESULTS";

// SEARCH ----------------------------------------------------------------------------------------- /
export const SEARCH_PAGE_QUERY_START = "SEARCH_PAGE_QUERY_START";
export const USER_SEARCH_QUERIED = "USER_SEARCH_QUERIED";
export const POST_SEARCH_QUERIED = "POST_SEARCH_QUERIED";
export const COMMENT_SEARCH_QUERIED = "COMMENT_SEARCH_QUERIED";
export const NEXT_POST_SEARCH_QUERIED = "NEXT_POST_SEARCH_QUERIED";
export const NEXT_COMMENT_SEARCH_QUERIED = "NEXT_COMMENT_SEARCH_QUERIED";
export const VIDEO_SEARCH_QUERIED = "VIDEO_SEARCH_QUERIED";
export const NEXT_VIDEO_SEARCH_QUERIED = "NEXT_VIDEO_SEARCH_QUERIED";
export const EVENT_SEARCH_QUERIED = "EVENT_SEARCH_QUERIED";
export const NEXT_EVENT_SEARCH_QUERIED = "NEXT_EVENT_SEARCH_QUERIED";

// ADVANCED SEARCH ----------------------------------------------------------------------------------------- /
export const ADVANCED_SEARCH_PAGE_QUERY_START = "ADVANCED_SEARCH_PAGE_QUERY_START";
export const SET_ADVANCED_SEARCH = "SET_ADVANCED_SEARCH";
export const CLEAR_ADVANCED_SEARCH = "CLEAR_ADVANCED_SEARCH";
export const ADVANCED_SEARCH_PENDING = "ADVANCED_SEARCH_PENDING";
export const CLEAR_PENDING_ADVANCED_SEARCH = "CLEAR_PENDING_ADVANCED_SEARCH";
export const ADVANCED_SEARCH_QUERIED = "ADVANCED_SEARCH_QUERIED";
export const USER_ADVANCED_SEARCH_QUERIED = "USER_ADVANCED_SEARCH_QUERIED";
export const POST_ADVANCED_SEARCH_QUERIED = "POST_ADVANCED_SEARCH_QUERIED";
export const COMMENT_ADVANCED_SEARCH_QUERIED = "COMMENT_ADVANCED_SEARCH_QUERIED";
export const NEXT_POST_ADVANCED_SEARCH_QUERIED = "NEXT_POST_ADVANCED_SEARCH_QUERIED";
export const NEXT_COMMENT_ADVANCED_SEARCH_QUERIED = "NEXT_COMMENT_ADVANCED_SEARCH_QUERIED";
export const VIDEO_ADVANCED_SEARCH_QUERIED = "VIDEO_ADVANCED_SEARCH_QUERIED";
export const NEXT_VIDEO_ADVANCED_SEARCH_QUERIED = "NEXT_VIDEO_ADVANCED_SEARCH_QUERIED";
export const EVENT_ADVANCED_SEARCH_QUERIED = "EVENT_ADVANCED_SEARCH_QUERIED";
export const NEXT_EVENT_ADVANCED_SEARCH_QUERIED = "NEXT_EVENT_ADVANCED_SEARCH_QUERIED";

export const LOCATIONS_LOADED = "LOCATIONS_LOADED";
export const LOCATION_UPDATED = "LOCATION_UPDATED";
export const MAP_LOCATIONS_LOADED = "MAP_LOCATIONS_LOADED";

export const NOTIFICATION_SETTINGS_CREATED = "NOTIFICATION_SETTINGS_CREATED";
export const NOTIFICATION_SETTINGS_UPDATED = "NOTIFICATION_SETTINGS_UPDATED";

export const APPLICATION_QUEUE_LOADED = "APPLICATION_QUEUE_LOADED";
export const START_CREATE_USER_EVALUATION = "START_CREATE_USER_EVALUATION";
export const START_LOAD_USER_EVALUATION = "START_LOAD_USER_EVALUATION";
export const USER_EVALUATION_LOADED = "USER_EVALUATION_LOADED";
export const USER_EVALUATION_CREATED = "USER_EVALUATION_CREATED";
export const USER_EVALUATION_CREATED_ERROR = "USER_EVALUATION_CREATED_ERROR";
export const USER_EVALUATION_UPDATED = "USER_EVALUATION_UPDATED";
export const USER_EVALUATION_DELETED = "USER_EVALUATION_DELETED";
export const USER_EVALUATION_FINALIZED = "USER_EVALUATION_FINALIZED";
export const USER_EVALUATION_FIELD_CREATED = "USER_EVALUATION_FIELD_CREATED";
export const USER_EVALUATION_FIELD_UPDATED = "USER_EVALUATION_FIELD_UPDATED";
export const EVALUATION_RESPONSE_TEMPLATES_LOADED = "EVALUATION_RESPONSE_TEMPLATES_LOADED";

export const INSTRUMENT_CATEGORIES_LOADED = "INSTRUMENT_CATEGORIES_LOADED";
export const INSTRUMENT_CREATED = "INSTRUMENT_CREATED";
export const INSTRUMENTS_LOADED = "INSTRUMENTS_LOADED";

export const INFLUENCER_CREATED = "INFLUENCER_CREATED";
export const INFLUENCERS_LOADED = "INFLUENCERS_LOADED";

export const NOTIFICATION_SETTINGS_LOADED = "NOTIFICATION_SETTINGS_LOADED";
export const USER_PROFILE_SAVED = "USER_PROFILE_SAVED";
export const START_SAVE_USER_PROFILE = "START_SAVE_USER_PROFILE";
export const START_SAVE_USER_EMAIL = "START_SAVE_USER_EMAIL";
export const USER_PROFILE_SAVED_ERROR = "USER_PROFILE_SAVED_ERROR";

export const OPEN_CHAT_POPUP = "OPEN_CHAT_POPUP";
export const CLOSE_CHAT_POPUP = "CLOSE_CHAT_POPUP";
export const CONVERSATION_CREATED = "CONVERSATION_CREATED";
export const CONVERSATION_UPDATED = "CONVERSATION_UPDATED";
export const CONVERSATION_SELECTED = "CONVERSATION_SELECTED";
export const CONVERSATION_CLEARED = "CONVERSATION_CLEARED";
export const CONVERSATION_LOADED = "CONVERSATION_LOADED";
export const CONVERSATIONS_LOADED = "CONVERSATIONS_LOADED";

export const START_MESSAGE_SENDING = "START_MESSAGE_SENDING";
export const MESSAGE_SENT = "MESSAGE_SENT";

export const NOTIFICATIONS_LOADED = "NOTIFICATIONS_LOADED";
export const NOTIFICATIONS_READ = "NOTIFICATIONS_READ";
export const NOTIFICATION_UPDATED = "NOTIFICATION_UPDATED";
export const NOTIFICATIONS_LOAD_ERROR = "NOTIFICATIONS_LOAD_ERROR";

export const STRIPE_TARGET_ACCOUNTS_LOADED = "STRIPE_TARGET_ACCOUNTS_LOADED";
export const STRIPE_TARGET_ACCOUNT_CREATED = "STRIPE_TARGET_ACCOUNT_CREATED";
export const STRIPE_TARGET_ACCOUNT_UPDATED = "STRIPE_TARGET_ACCOUNT_UPDATED";
export const STRIPE_TARGET_ACCOUNT_DELETED = "STRIPE_TARGET_ACCOUNT_DELETED";

export const USER_SUBSCRIPTION_STARTED = "USER_SUBSCRIPTION_STARTED";
export const USER_PAYMENTS_LOADED = "USER_PAYMENTS_LOADED";
export const USER_PAYMENTS_ADDED = "USER_PAYMENTS_ADDED";
export const USER_PAYMENT_REMOVED = "USER_PAYMENT_REMOVED";
export const USER_PAYMENT_UPDATED = "USER_PAYMENT_UPDATED";

export const SET_ACTIVE_FEED_ID = "SET_ACTIVE_FEED_ID";

export const FREE_TRIAL_TOUR_STARTED = "FREE_TRIAL_TOUR_STARTED";
export const FREE_TRIAL_TOUR_COMPLETE = "FREE_TRIAL_TOUR_COMPLETE";
export const FREE_TRIAL_TOUR_CLOSED = "FREE_TRIAL_TOUR_CLOSED";
export const FREE_TRIAL_TOUR_NOT_STARTED = "FREE_TRIAL_TOUR_NOT_STARTED";

// QUOTE ACTIONS
export const MOST_RECENT_QUOTE_LOADED = "MOST_RECENT_QUOTE_LOADED";
export const QUOTES_LOADED = "QUOTES_LOADED";
export const QUOTE_LOADED = "QUOTE_LOADED";
export const QUOTE_CREATED = "QUOTE_CREATED";
export const QUOTE_UPDATED = "QUOTE_UPDATED";
export const QUOTE_DELETED = "QUOTE_DELETED";

// PRIVATE GROUP ACTIONS
export const PRIVATE_GROUP_LOADED = "PRIVATE_GROUP_LOADED";

// PUBLIC GROUP ACTIONS
export const PUBLIC_GROUP_CREATED = "PUBLIC_GROUP_CREATED";

// TAG ACTIONS
export const STUDIO_TAG_CREATED = "STUDIO_TAG_CREATED";

// WINDOW EVENTS
export const WINDOW_BLUR_EVENT = "WINDOW_BLUR_EVENT";
export const WINDOW_FOCUS_EVENT = "WINDOW_FOCUS_EVENT";

// REGISTRATION FORM
export const REGISTRATION_FORM_LOADED = "REGISTRATION_FORM_LOADED";
export const REGISTRATION_COMPLETE = "REGISTRATION_COMPLETE";
export const REGISTRATION_RESPONSES_LOADED = "REGISTRATION_RESPONSES_LOADED";

// CACHE STATE
export const BUST_CACHE = "BUST_CACHE";
export const CACHE_BUSTED = "CACHE_BUSTED";

// GENERAL POSTER
export const RESET_TEXT = "RESET_TEXT";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";

// CONTENT
export const AUDIO_DETAILS_LOADED = "AUDIO_DETAILS_LOADED";
export const VIDEO_POSTER_UPDATED = "VIDEO_POSTER_UPDATED";

// SITE / MARKETING / PROMOS
export const PROMO_PAGE_DETAILS_LOADED = "PROMO_PAGE_DETAILS_LOADED";
export const PROMO_DOWNLOAD_SUCCESS = "PROMO_DOWNLOAD_SUCCESS";
export const NEWSLETTER_SIGNUP_SUCCESS = "NEWSLETTER_SIGNUP_SUCCESS";
export const NEWSLETTER_SIGNUP_ERROR = "NEWSLETTER_SIGNUP_ERROR";
export const PROMO_PAGES_LOADED = "PROMO_PAGES_LOADED";
export const PROMO_PAGE_LOADED = "PROMO_PAGE_LOADED";
export const PROMO_PAGE_UPDATED = "PROMO_PAGE_UPDATED";
export const PROMO_PAGE_CREATED = "PROMO_PAGE_CREATED";
export const CONTACT_LISTS_LOADED = "CONTACT_LISTS_LOADED";

export const MENU_ITEMS_LOADED = "MENU_ITEMS_LOADED";
export const STAFF_LOADED = "STAFF_LOADED";
export const STAFF_DETAILS_LOADED = "STAFF_DETAILS_LOADED";
export const BIOS_LOADED = "BIOS_LOADED";
export const BIO_DETAILS_LOADED = "BIO_DETAILS_LOADED";

export const PUBLIC_EVENTS_LOADED = "PUBLIC_EVENTS_LOADED";
export const PUBLIC_EVENT_DETAILS_LOADED = "PUBLIC_EVENT_DETAILS_LOADED";
export const PUBLIC_EVENT_MENU_ITEMS_LOADED = "PUBLIC_EVENT_MENU_ITEMS_LOADED";

export const COMMUNITY_MEMBERS_LOADED = "COMMUNITY_MEMBERS_LOADED";

// SITE NOTIFICATIONS
export const START_SITE_NOTIFICATIONS_LOAD = "START_SITE_NOTIFICATIONS_LOAD";
export const SITE_NOTIFICATIONS_LOAD_ERROR = "SITE_NOTIFICATIONS_LOAD_ERROR";
export const SITE_NOTIFICATIONS_LOADED = "SITE_NOTIFICATIONS_LOADED";
export const SITE_NOTIFICATION_CREATED = "SITE_NOTIFICATION_CREATED";
export const SITE_NOTIFICATION_PUBLISHED = "SITE_NOTIFICATION_PUBLISHED";
export const SITE_NOTIFICATION_CREATED_ERROR = "SITE_NOTIFICATION_CREATED_ERROR";
export const SITE_NOTIFICATION_UPDATED = "SITE_NOTIFICATION_UPDATED";
export const SITE_NOTIFICATION_DELETED = "SITE_NOTIFICATION_DELETED";
export const SITE_NOTIFICATION_DETAILS_LOADED = "SITE_NOTIFICATION_DETAILS_LOADED";
export const SITE_NOTIFICATIONS_CREATED_ERROR = "SITE_NOTIFICATIONS_CREATED_ERROR";

export const ACTIVE_SITE_NOTIFICATIONS_LOADED = "ACTIVE_SITE_NOTIFICATIONS_LOADED";
export const ACTIVE_SITE_NOTIFICATION_DISMISSED = "ACTIVE_SITE_NOTIFICATION_DISMISSED";
