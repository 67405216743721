import React from "react";
import {hot} from "react-hot-loader";
import {Provider} from "react-redux";
import {ToastContainer} from "react-toastify";
import getStore from "./utils/getStore";
import HomepageContent from "components/homepage/page/HomePageContent";

function HomepageRoot() {

    return (
        <Provider store={getStore()}>
            <main className="homepage_root">
                <HomepageContent />
                <ToastContainer />
            </main>
        </Provider>
    );
}

if (DEVELOPMENT) {
    HomepageRoot = hot(module)(HomepageRoot);
}

export default HomepageRoot;
