import React from "react";
import PropTypes from "prop-types";
import styles from "./MeetJeff.scss";
import getStaticFile from "utils/getStaticFile";
const jeffImage = getStaticFile("img/meet_team.jpg");
const boltDecorationImage = getStaticFile("img/decorations/decoration_bolt.png");

export default function MeetJeff(props) {
    return (
        <section className={styles.root}>

            <div className={styles.jeffPic}>
                <img src={jeffImage} alt="Jeff Antoniuk" />
                <div className={styles.decoration}>
                    <img src={boltDecorationImage}></img>
                </div>
            </div>

            <div className={styles.textBlock}>
                <div className={styles.inner}>
                    <h2 className={styles.meetJeff}>
                        Meet The Team
                    </h2>
                    <h3 className={styles.missionStatement}>
                        {props.missionStatement}
                    </h3>
                    <p className={styles.bio}>
                        {props.bio}
                    </p>
                </div>
            </div>
        </section>
    );
}

MeetJeff.propTypes = {
    missionStatement: PropTypes.string.isRequired,
    bio: PropTypes.string.isRequired,
};
