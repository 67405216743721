import {combineReducers} from "redux";
import * as ActionTypes from "constants/ActionTypes";
import filter from "lodash/filter";


// ADVANCED SEARCH -------------------------------------------------------------------------------------------- /
function searchString(state = "", action) {
    switch (action.type) {
        case ActionTypes.SET_ADVANCED_SEARCH:
            return action.searchString;
        case ActionTypes.ADVANCED_SEARCH_PAGE_QUERY_START:
            return action.searchString;
        case ActionTypes.CLEAR_ADVANCED_SEARCH:
            return "";
        default:
            return state;
    }
}

function advancedSearchPending(state = false, action) {
    switch (action.type) {
        case ActionTypes.ADVANCED_SEARCH_PENDING:
            return true;
        case ActionTypes.CLEAR_PENDING_ADVANCED_SEARCH:
            return false;
        default:
            return state;
    }
}

function advancedSearchPageLoadingState(state={}, action) {
    switch (action.type) {
        case ActionTypes.ADVANCED_SEARCH_PAGE_QUERY_START:
            return {
                ...state,
                [action.query]: true,
            }
        case ActionTypes.ADVANCED_SEARCH_QUERIED:
            return {
                ...state,
                all: false,
            }
        case ActionTypes.USER_ADVANCED_SEARCH_QUERIED:
            return {
                ...state,
                users: false,
            };
        case ActionTypes.POST_ADVANCED_SEARCH_QUERIED:
            return {
                ...state,
                posts: false,
            };
        case ActionTypes.COMMENT_ADVANCED_SEARCH_QUERIED:
            return {
                ...state,
                comments: false,
            };
        case ActionTypes.VIDEO_ADVANCED_SEARCH_QUERIED:
            return {
                ...state,
                videos: false,
            };
        case ActionTypes.EVENT_ADVANCED_SEARCH_QUERIED:
            return {
                ...state,
                events: false,
            };
        default:
            return state;
    }
}

function advancedSearchResults(state = [], action) {
    switch (action.type) {
        case ActionTypes.ADVANCED_SEARCH_PAGE_QUERY_START:
            return [];
        case ActionTypes.ADVANCED_SEARCH_QUERIED:
            return action.searchResults;
        default:
            return state;
    }
}

function userAdvancedSearchResults(state= [], action) {
    switch (action.type) {
        case ActionTypes.ADVANCED_SEARCH_PAGE_QUERY_START:
            return [];
        case ActionTypes.USER_ADVANCED_SEARCH_QUERIED:
            return action.userSearchResults;
        default:
            return state;
    }
}

function postAdvancedSearchResults(state= [], action) {
    switch (action.type) {
        case ActionTypes.ADVANCED_SEARCH_PAGE_QUERY_START:
            return [];
        case ActionTypes.ADVANCED_SEARCH_QUERIED:
            return  filter(action.searchResults, { modelName: "post", postType: "post" });
        default:
            return state;
    }
}

function commentAdvancedSearchResults(state= [], action) {
    switch (action.type) {
        case ActionTypes.ADVANCED_SEARCH_PAGE_QUERY_START:
            return [];
        case ActionTypes.ADVANCED_SEARCH_QUERIED:
            return  filter(action.searchResults, { modelName: "comment"});
        default:
            return state;
    }
}

function videoAdvancedSearchResults(state= [], action) {
    switch (action.type) {
        case ActionTypes.ADVANCED_SEARCH_PAGE_QUERY_START:
            return [];
        case ActionTypes.ADVANCED_SEARCH_QUERIED:
            return  filter(action.searchResults, { modelName: "post", postType: "video" });
        default:
            return state;
    }
}

function eventAdvancedSearchResults(state = [], action) {
    switch (action.type) {
        case ActionTypes.ADVANCED_SEARCH_PAGE_QUERY_START:
            return [];
        case ActionTypes.ADVANCED_SEARCH_QUERIED:
            return  filter(action.searchResults, { modelName: "event" });
        default:
            return state;
    }
}
// -------------------------------------------------------------------------------------------- ADVANCED SEARCH /


export default combineReducers({
    searchString,
    advancedSearchPageLoadingState,
    advancedSearchPending,
    advancedSearchResults,
    commentAdvancedSearchResults,
    eventAdvancedSearchResults,
    postAdvancedSearchResults,
    userAdvancedSearchResults,
    videoAdvancedSearchResults,
});
