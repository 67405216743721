
import {toast} from "react-toastify";


export const toaster = {
    error: (content) => toast.error(content),
    success: (content) => toast.success(content),
    warn: (content) => toast.warn(content),
    info: (content) => toast.info(content),
};
