import React, {useState, useEffect } from "react";
import PropTypes from "prop-types";

export default function ProgressBar(props) {

    const [barColor, setBarColor] = useState("blue");
    const [barStyle, setBarStyle] = useState({
        height: "1.5em",
        position: "relative",
        width: "100%",
    });

    useEffect(() => {
        if (props.colors?.[0]) {
            setBarColor(props.colors[0]);
        }
        if (props.border) {
            setBarStyle({
                ...barStyle,
                border: "1px solid #D5D5D5",
            });
        }
    }, []);

    useEffect(() => {
        if (!!props.thresholds?.length && props.colors?.length === props.thresholds.length) {
            const reverseColors = props.colors.reverse();
            const reverseThresholds = props.thresholds.reverse();
            for(let i=0;i < reverseThresholds; i++) {
                if (props.percent >= value) {
                    setBarColor(reverseColors[index]);
                    break;
                }
            }
        }
    }, [props.percent]);

    return (
        <div
            style={barStyle}
        >
            <div
                style={{
                    position: "absolute",
                    height: "1.2em",
                    top: ".1em",
                    left: "0.1em",
                    width: `${props.percent}%`,
                    backgroundColor: `${barColor}`
                }}
            />
        </div>
    )
}

ProgressBar.propTypes = {
    percent: PropTypes.number.isRequired,
    colors: PropTypes.arrayOf(PropTypes.string),
    thresholds: PropTypes.arrayOf(PropTypes.number),
    border: PropTypes.bool,
};