
import React from "react";
import ReactDOM from "react-dom";
import Root from "HomepageRoot";

ReactDOM.render(<Root />, document.getElementById("app"));

if (DEVELOPMENT) {
    module.hot.accept("HomepageRoot", (e) => {
        const Root = require("HomepageRoot").default;
        ReactDOM.render(<Root />, document.getElementById("app"));
    });
    __webpack_public_path__ = DEVELOPMENT_SERVER_STATIC_PATH;
}

