import getStaticFile from "utils/getStaticFile";

export const EventSessions = [
    {
        title: "JAZZWIRE SUBSCRIPTION",
        description: "If you're looking to get motivated and inspired to meet your jazz improv goals, the JazzWire Subscription service is a great way to do it. You'll have access to 900+ tune-based videos, 1000+ technique-based pdfs, and a supportive community of fellow adult-learners who are working hard to become better jazz players. Sign up today!",
        image: getStaticFile("img/events/events_subscription.jpg"),
        logo: getStaticFile("img/events/jazzwire_subscription_logo.png"),
        eventType: {
            color: "#dbb602",
            slug: "jazzwire-subscription"
        },
        order: 1,
        link: null
    },
    {
        title: "JAZZWIRE MASTERCLASS",
        description: "Come improve your jazz skills in a combo setting! Led by a certified JazzWire coach, you'll learn about communication and ensemble cohesiveness, as well as becoming a better jazz improviser.  Spots are limited.  Inquire today about openings.",
        image: getStaticFile("img/events/events_masterclass.jpg"),
        logo: getStaticFile("img/events/jazzwire_masterclass_logo.png"),
        eventType: {
            color: "#000000",
            slug: "jazzwire-masterclass"
        },
        order: 2,
        link: null
    },
    {
        title: "JAZZWIRE SUMMITS",
        description: "Our Summits bring together many of the world’s greatest jazz musicians to form a residential community of creativity. Students learn from top jazz artists during the day, and see/hear them perform at night. Click here to view dates and Summit locations near you.",
        image: getStaticFile("img/events/events_summits.jpg"),
        logo: getStaticFile("img/events/jazzwire_summits_logo.png"),
        eventType: {
            color: "#8c92ac",
            slug: "jazzwire-summits"
        },
        order: 3,
        link: null
    },
    {
        title: "JAZZWIRE EDU",
        description: "JazzWire-EDU is a great resource for jazz students, administered by college and university music programs. It provides evaluations, lessons, and a school-defined online community that keeps students engaged and inspired outside of the classroom and studio. Music School Administrators should check it out to learn more.",
        image: getStaticFile("img/events/events_edu.jpg"),
        logo: getStaticFile("img/events/jazzwire_edu_logo.png"),
        eventType: {
            color: "#18dbd5",
            slug: "jazzwire-edu"
        },
        order: 4,
        link: "https://www.jazzwire-edu.net/"
    },
    {
        title: "JAZZWIRE LIVE",
        description: "This is a variation of our Summit Program but in a different geographic region. It features select members of our JazzWire coaching staff and inspirational jazz educators from that region. Click here to view events near you.",
        image: getStaticFile("img/events/events_live.jpg"),
        logo: getStaticFile("img/events/jazzwire_live_logo.png"),
        eventType: {
            color: "#db2eb7",
            slug: "jazzwire-live"
        },
        order: 5,
        link: null
    },
    {
        title: "JAZZWIRE SESSIONS",
        description: "Musicians often feel intimidated at the thought of going to a jam session. However, it can be one of the most rewarding musical experiences there is. The JazzWire Jam Session will help you break past your inhibitions and enjoy the spontaneous musical collaboration of a jam session.  Click here to view the next session near you.",
        image: getStaticFile("img/events/events_sessions.jpg"),
        logo: getStaticFile("img/events/jazzwire_sessions_logo.png"),
        eventType: {
            color: "#ff781d",
            slug: "jazzwire-sessions"
        },
        order: 6,
        link: null
    },
];