
import {combineReducers} from "redux";
import * as ActionTypes from "constants/ActionTypes";
import findIndex from "lodash/findIndex";
import keyBy from "lodash/keyBy";
import produce from "immer";

function userProfile(state={}, action) {
    switch (action.type) {
        case ActionTypes.CURRENT_USER_LOADED:
        case ActionTypes.USER_TRIAL_CONVERTED:
        case ActionTypes.USER_REJOIN_STUDIO:
            const { isAdmin, isSiteAdmin, isStudioTeacher } = action.userProfile;
            const useAdvancedPoster = isAdmin || isSiteAdmin || isStudioTeacher;
            return {
                ...action.userProfile,
                forceEvaluation: true,
                userStudios: {
                    ...keyBy(action.userProfile.userStudios, "id"),
                },
                studios: {
                    ...keyBy(action.userProfile.studios, "id"),
                },
                following: {
                    ...keyBy(action.userProfile.following, "id"),
                },
                useAdvancedPoster,
            };
        case ActionTypes.CURRENT_USER_UPDATED:
            return {
                ...state,
                ...action.userProfile,
            };
        case ActionTypes.USER_STUDIO_UPDATED:
            const existingUserStudio = state.userStudios[action.userStudio.id];
            return produce(state, (newState) => {
                newState.userStudios[action.userStudio.id] = {
                    ...action.userStudio,
                    studio: existingUserStudio ? existingUserStudio.studio : action.userStudio.studio,
                };
            });
        case ActionTypes.NOTIFICATION_SETTINGS_CREATED:
            return {
                ...state,
                notificationSettings: action.notificationSettings,
            };
        case ActionTypes.USER_INSTRUMENT_UPDATED:
            return produce(state, (newState) => {
                const instrumentIndex = findIndex(newState.instruments,  ['id', action.instrument.id]);
                if(instrumentIndex) {
                    newState.instruments[instrumentIndex] = action.instrument;
                }
            });
        case ActionTypes.USER_INSTRUMENT_DELETED:
            return produce(state, (newState) => {
                const instrumentIndex = findIndex(newState.instruments,  ['id', action.instrumentId]);
                if(instrumentIndex) {
                    newState.instruments[instrumentIndex] = newState.instruments.splice(instrumentIndex, 1);
                }
            });
        case ActionTypes.USER_INSTRUMENT_ADDED:
            return produce(state, (newState) => {
                newState.instruments.push(action.instrument);
            });
        case ActionTypes.EVALUATION_REMINDER_DISMISSED:
            //If a user evaluation reminder is dismissed then we need to stop showing it
            return produce(state, (newState) => {
                newState.showNewEvaluationMessage = false;
            });
        case ActionTypes.USER_EVALUATION_CREATED:
            //If a user evaluation is submitted then we need to stop allowing new evaluations
            return produce(state, (newState) => {
                newState.allowNewEvaluation = false;
                newState.showNewEvaluationMessage = false;
            });
        case ActionTypes.USER_SUBSCRIPTION_STARTED:
            return {
                ...state,
                ...action.userProfile,
            };
        case ActionTypes.USER_FOLLOWED:
			return produce(state, (newState) => {
                if (newState.following[action.userDetails.userStudioId]) {
                    delete newState.following[action.userDetails.userStudioId];
                } else {
                    newState.following[action.userDetails.userStudioId] = action.userDetails;
                }
            });
        default:
            return state;
    }
}

function studioList(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CURRENT_USER_LOADED:
            return keyBy(action.userProfile.studios, "id");
        default:
            return state;
    }
}

function conversations(state={}, action) {
    switch (action.type) {
        case ActionTypes.CONVERSATION_LOADED:
            return produce(state, (newState) => {
                newState[action.userConversation.id] = action.userConversation.conversation.messages;
            });
        case ActionTypes.MESSAGE_SENT:
            return produce(state, (newState) => {
                 newState[action.userConversation.id] = action.userConversation.conversation.messages;
            });
        default:
            return state;
    }
}

function conversationList(state = [], action) {
    switch (action.type) {
        case ActionTypes.CONVERSATIONS_LOADED:
            return action.conversations;
        case ActionTypes.CONVERSATION_CREATED:
            return produce(state, (newState) => {
                    newState.unshift(action.userConversation);
                });
        case ActionTypes.CONVERSATION_UPDATED:
        case ActionTypes.MESSAGE_SENT:
            return produce(state, (newState) => {
                    const oldIndex = findIndex(state, conv => conv.id === action.userConversation.id);
                    newState.splice(oldIndex, 1);
                    newState.unshift(action.userConversation);
                });
        default:
            return state;
    }
}

function activeConversationId(state=null, action) {
    switch (action.type) {
        case ActionTypes.CONVERSATION_CREATED:
            return action.userConversation.id;
        case ActionTypes.CONVERSATION_SELECTED:
            return action.id;
        case ActionTypes.CONVERSATION_CLEARED:
            return null;
        default:
            return state;

    }
}

function notificationSettings(state={}, action) {
    switch (action.type) {
        case ActionTypes.NOTIFICATION_SETTINGS_LOADED:
        case ActionTypes.NOTIFICATION_SETTINGS_UPDATED:
            return action.notificationSettings;
        default:
            return state;
    }
}

function paymentMethods(state={}, action) {
    switch(action.type) {
        case ActionTypes.USER_PAYMENTS_LOADED:
            return {
                ...state,
                [action.account]: action.paymentMethods,
            };
        case ActionTypes.USER_PAYMENTS_ADDED:

            return {
                ...state,
                [action.account]: {
                    ...state[action.account],
                    paymentSources: produce(state[action.account].paymentSources, (newState) => {
                        newState.push(action.paymentMethod);
                    }),
                    defaultPaymentSource: action.paymentMethod.id,
                }
            };
        case ActionTypes.USER_PAYMENT_REMOVED:
            return {
                ...state,
                [action.account]: {
                    ...state[action.account],
                    paymentSources: produce(state[action.account].paymentSources, (newState) => {
                        const index = findIndex(newState, pm => pm.id === action.updatedPaymentMethods.removedPaymentMethodId);
                        newState.splice(index, 1);
                    }),
                    defaultPaymentSource: action.updatedPaymentMethods.nextDefaultPaymentMethodId ? action.updatedPaymentMethods.nextDefaultPaymentMethodId : state.defaultPaymentSource,
                }
            };
        case ActionTypes.USER_PAYMENT_UPDATED:
            return {
                ...state,
                [action.account]: {
                    ...state[action.account],
                    defaultPaymentSource: action.paymentMethodId,
                }
            };

        default:
            return state;
    }
}

export default combineReducers({
    userProfile,
    conversations,
    conversationList,
    paymentMethods,
    activeConversationId,
    notificationSettings,
    studioList,
});
