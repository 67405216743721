
import React from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.scss";
import classnames from "classnames";
import PropTypes from "prop-types";


export default class Modal extends React.Component {
    static propTypes = {
        clickOutsideToClose: PropTypes.bool,
        onClose: PropTypes.func,
        show: PropTypes.bool,
        showCloseButton: PropTypes.bool,
        small: PropTypes.bool,
        large: PropTypes.bool,
        centered: PropTypes.bool,
        modalTitle: PropTypes.string,
    };

    static defaultProps = {
        clickOutsideToClose: true,
        small: false,
        large: false,
        showCloseButton: true,
    };

    constructor(props) {
        super(props);
        this.el = document.createElement("div");
    }

    componentDidMount() {
        document.getElementById("modal-root").appendChild(this.el);
    }

    componentWillUnmount() {
        document.getElementById("modal-root").removeChild(this.el);
    }

    render() {
        let component;

        if (this.props.show) {
            component = (
                <div
                    className={classnames(styles.root, styles.overlayModal,  {
                        [styles.small]: this.props.small,
                        [styles.large]: this.props.large,
                        [styles.centered]: this.props.centered,
                    })}
                >
                    <div
                        className={styles.modalBackdrop}
                        onClick={this.props.clickOutsideToClose ? this.props.onClose : () => {}}
                    />
                    <div className={styles.modalContainer}>
                        {this.props.modalTitle &&
                            <div className={styles.modalTitle}>
                                <h3>{this.props.modalTitle}</h3>
                            </div>
                        }
                        <div className={styles.modalBodyContainer}>
                            {this.props.children}
                        </div>
                        {this.props.showCloseButton &&
                            (<span
                                className={styles.closeBtn}
                                onClick={this.props.onClose}
                            >
                                <i className="far fa-times-circle" />
                            </span>)
                        }
                    </div>
                </div>
            );
        }

        return ReactDOM.createPortal(
            component,
            this.el,
        );
    }
}
