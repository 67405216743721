import React from "react";
import PropTypes from "prop-types";
import styles from "./TextBlock.scss";
import classnames from "classnames";

import getStaticFile from "utils/getStaticFile";
const border = getStaticFile("img/white_lines.svg");

export default function TextBlock(props) {
    const inlineStyles = {
        backgroundColor: props.color,
    }
    if(props.textColor) {
        inlineStyles["color"] = props.textColor;
    }
    if(props.lineHeight) {
        inlineStyles["lineHeight"] = props.lineHeight;
    }

    const textStyles = {}
    if(props.maxTextWidth) {
        textStyles["maxWidth"] = `${props.maxTextWidth}px`;
    }

    if(props.maxTextWidth && props.contentRight) {
        textStyles["margin"] = "0 0 0 auto";
    }

    return (
        <section className={classnames(styles.root, {
            [styles.noBorders]: props.hideBorders,
            [styles.noPadding]: props.noPadding,
            [styles.useFlex]: props.useFlex,
            [styles.lessPadding]: props.isFlexChild,
            [styles.contentTop]: props.contentTop,
        })} style={inlineStyles}>
            <div className="container">
                <div className={styles.borderWrap}>
                    <div className={styles.topBorder}>
                        <img src={border} alt="border" />
                    </div>
                </div>
                <div className={classnames(styles.content, {
                    [styles.singleColumn]: props.singleColumn,
                    [styles.leftAligned]: props.leftAlign,
                })}>
                    <div className={styles.innerContainer} style={textStyles}>
                        <div>
                        {props.header && 
                            <div className={styles.header}>
                                {props.header}
                            </div>
                        }
                        {props.content}
                        </div>
                    </div>
                </div>
                <div className={styles.borderWrap}>
                    <div className={styles.bottomBorder}>
                        <img src={border} alt="border" />
                    </div>
                </div>
            </div>
        </section>
    );
}

TextBlock.propTypes = {
    header: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    color: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    lineHeight: PropTypes.string,
    singleColumn: PropTypes.bool,
    hideBorders: PropTypes.bool,
    noPadding: PropTypes.bool,
    leftAlign: PropTypes.bool,
    useFlex: PropTypes.bool,
    contentRight: PropTypes.bool,
    contentTop: PropTypes.bool,
    maxTextWidth: PropTypes.number,
    isFlexChild: PropTypes.bool,
};
