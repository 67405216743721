import React from "react";
import styles from "./Site.scss";
import PropTypes from "prop-types";
import HeaderBlock from "components/shared/widgets/HeaderBlock";
import Footer from "components/shared/widgets/Footer";
import getStaticFile from "utils/getStaticFile";
import MinimalHeader from "components/shared/widgets/MinimalHeader";
import SiteNav from "components/shared/widgets/SiteNav";

const headerLogo = getStaticFile("img/logo_white.png");

export default function SiteContainer(props) {

    const renderSiteHeader = () => {
        if (props.minimal) {
            return (
                <MinimalHeader logo={headerLogo}>
                    <SiteNav logo={headerLogo} />
                </MinimalHeader>
            );
        }

        return (
            <HeaderBlock
                backgroundImage={props.headerImage}
                logo={headerLogo}
                tagLine={props.tagLine}
                subText={props.subText}
                shorter={props.shorter}
            >
                <SiteNav logo={headerLogo} />
            </HeaderBlock>
        );
    }

    return (
        <main className={styles.root}>
            {renderSiteHeader()}
            {props.children}
            <Footer />
        </main>
    );
}

SiteContainer.propTypes = {
    headerImage: PropTypes.string,
    tagLine: PropTypes.any,
    subText: PropTypes.string,
    shorter:PropTypes.bool,
    minimal: PropTypes.bool,
}