import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import styles from "./SiteNav.scss";
import classnames from "classnames";
import PropTypes from "prop-types";
import map from "lodash/map";
import HamburgerMenu from "components/homepage/elements/HamburgerMenu";
import MainMenu from "components/shared/widgets/MainMenu";
import * as SiteActions from "actions/SiteActions";
import {checkLinkForRoute} from "utils/routingHelpers";


function SiteNav(props) {
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        props.loadMenuLinks();
        props.loadPublicEventMenuLinks();
    }, []);

    const renderMenuBar = () => {
        return map(props.menuLinks?.menuBar, item => getRouteOrLink(item, styles.link, "main_links"));
    }

    if(!props.menuLinks) {
        return null;
    }

    const getRouteOrLink = (item, linkClass, keyBase) => {
        const { routeLink, useRoute } = checkLinkForRoute(item.link, props.currentApp);
        if(useRoute) {
            return (
                <Link to={routeLink}
                    className={linkClass}
                    key={`${keyBase}_${item.id}`}
                >
                    {item.label}
                </Link>);
        }

        return (
            <a href={item.link} className={styles.link} key={`main_links_${item.id}`}>
                {item.label}
            </a>
        );
    }

    return (
        <div className={styles.root}>
            <div className={styles.links}>
                {renderMenuBar()}
                <a href="/app" className={classnames(styles.link, styles.login)}>
                    LOGIN
                </a>
            </div>
            <div className={styles.menuBtn}>
                {!showMenu && <HamburgerMenu bars onClick={() => setShowMenu(true)}/>}
            </div>
            <MainMenu
                onClose={() => setShowMenu(false)}
                show={showMenu}
                logo={props.logo}
                menuItems={props.menuLinks.menuItems}
                events={props.events}
                currentApp={props.currentApp}
            />
        </div>
    );
}

SiteNav.propTypes = {
    logo: PropTypes.string.isRequired,
    currentApp: PropTypes.string,
};


export default connect((state) => ({
        currentStudio: state.appState.currentStudio,
        userProfile: state.user.userProfile,
        menuLinks: state.site.menuLinks,
        events: state.site.events,
    }), (dispatch) => ({
        loadMenuLinks: () => dispatch(SiteActions.loadMenuLinks()),
        loadPublicEventMenuLinks: () => dispatch(SiteActions.loadPublicEventMenuLinks()),
    })
)(SiteNav);