
export const LIGHT_BLUE = "rgb(101, 216, 212)";
export const DARK_YELLOW = "rgb(214, 182, 61)";
export const PURPLE = "rgb(141, 146, 170)";

// Updated Color Theme 02/07/2022
export const DEFAULT_BLUE = "rgb(35, 128, 219)";
export const JAZZWIRE_RED = "rgb(219, 57, 46)";
export const JAZZWIRE_LIGHT_BLUE = "rgb(25, 220, 213)";
export const JAZZWIRE_ORANGE = "rgb(219, 138, 2)";
export const JAZZWIRE_LIGHT_GREEN = "rgb(13,219,96)";

export const JAZZWIRE_BLUE = JAZZWIRE_LIGHT_BLUE;
export const JAZZWIRE_BLUE_GREEN = "rgb(25, 220, 149)";

export const GREEN = JAZZWIRE_LIGHT_GREEN;
export const RED = JAZZWIRE_RED;
export const BLUE = DEFAULT_BLUE;

export const PROGRESS_COLORS = () => {
    return [
        JAZZWIRE_LIGHT_BLUE,
        JAZZWIRE_BLUE_GREEN,
        JAZZWIRE_LIGHT_GREEN
    ]
}