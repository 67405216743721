import request from "utils/request";

export function loadContactLists() {
    return request()
        .get("/api/v1/contact-lists/")
        .send()
}

export function loadMenuLinks() {
    return request()
        .get("/api/v1/menu-links/")
        .send()
}

export function loadBios() {
    return request()
        .get("/api/v1/bios/")
        .send()
}

export function loadStaff() {
    return request()
        .get("/api/v1/bios/staff/")
        .send()
}

export function loadBioDetails(bioId) {
    return request()
        .get(`/api/v1/bios/${bioId}/`)
        .send()
}

export function loadBioBySlug(slug) {
    return request()
        .get(`/api/v1/bio-lookup/${slug}/`)
        .send()
}

export function loadPublicEvents(slug= '') {
    const filter = slug ? `?event_group__slug=${slug}` : '';
    return request()
        .get(`/api/v1/upcoming-events/${filter}`)
        .send()
}

export function loadEventTypes() {
    return request()
        .get("/api/v1/event-types/")
        .send()
}

export function loadPublicEventDetails(eventId) {
    return request()
        .get(`/api/v1/upcoming-events/${eventId}/`)
        .send()
}

export function loadPublicEventsMenu() {
    return request()
        .get("/api/v1/upcoming-events/menu-items")
        .send()
}

export function joinNewsletter({email, contactListId, firstName, lastName}) {
    const payload = { email };

    if (firstName) {
        payload.firstName = firstName;
    }

    if (lastName) {
        payload.lastName = lastName;
    }

    return request()
        .post(`/api/v1/join-newsletter/${contactListId}/`)
        .send(payload);
}

export function sendContactForm(formData) {
    return request()
        .post(`/api/v1/send-contact/`)
        .send(formData)
}

export function validateRecaptcha(token) {
    return request()
        .post('/api/v1/verify-recaptcha/')
        .send({ token });
}
