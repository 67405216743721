import reducers from "reducers/index";
import {createStore, applyMiddleware} from "redux";
import reduxThunk from "redux-thunk";


const store = createStore(
    reducers,
    applyMiddleware(
        reduxThunk,
    ),
);

export default function getStore() {
    return store;
}
