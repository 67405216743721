import {combineReducers} from "redux";
import * as ActionTypes from "constants/ActionTypes";
import produce from "immer";
import { cloneDeep, forEach, keyBy } from "lodash";
import omit from "lodash/omit";
import without from "lodash/without";


function eventList(state = {}, action) {
    switch (action.type) {
        case ActionTypes.EVENTS_LOADED:
            const { results, ...data } = action.eventsResponse;
            return {
                ...state,
                ...data,
                events: produce(state.events, (draftEvents) => {
                    return {
                        ...draftEvents,
                        ...keyBy(results, "uniqueId"),
                    };
                }),
            }
        case ActionTypes.EVENT_CREATED:
            return {
                ...state,
                total: state.total + 1,
                events: {
                    ...state.events,
                    [action.event.uniqueId]: action.event,
                },
            };
        case ActionTypes.EVENT_DELETED:
            return {
                ...state,
                total: state.total - 1,
                events: {
                    ...omit(state.events, action.event),
                },
            }
        default:
            return state;
    }
}

export default combineReducers({
    eventList,
});